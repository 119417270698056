import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Button, Select, MenuItem, FormControl, Snackbar, Alert } from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AddStock() {

    const [barcode, setBarcode] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [fabricationDate, setFabricationDate] = useState('');
    const [entryDate, setEntryDate] = useState('');
    const [location, setLocation] = useState('');
    const [product, setProduct] = useState('');
    const [products , setproducts] = useState([])
    const [open ,setOpen] = useState(false)
    const [opener ,setOpener] = useState(false)
    const [message , setmessage ] = useState("")
    const [Q, setQ] = useState(1);
    const navigate = useNavigate()
    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };

    useEffect(()=>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/produits")
        .then((res)=>{
            setproducts(res.data)
        })
    },[])

    const handlsubmit = async () => {
        try {
            for (let i = 0; i < Q; i++) {
                const data = {
                    Code_Bar: barcode + "|" + i,
                    Date_Expiration: expiryDate,
                    id_produits: product,
                    Emplacement: location,
                    Date_Fabrication: fabricationDate,
                    Date_Entree: entryDate
                };
                const res = await axios.post("https://serv.pestima.yuliagroupe.com/api/stocks", data);
                if (res.data.error) {
                    setmessage(res.data.message);
                    setOpener(true);
                    return; 
                }
            }
            setmessage("Stock bien Ajouter");
            setOpen(true);
            setTimeout(() => {
                navigate("/StockTable");
            }, 3000);
        } catch (error) {
            setmessage("erreur");
            setOpener(true);
        }
    };
    

    const handleCloseer = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpener(false);
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    
    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Ajouter Stock</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Code Bar</Typography>
                                <TextField
                                    fullWidth
                                    value={barcode}
                                    onChange={(e) => { setBarcode(e.target.value) }}
                                    id="barcode"
                                    name="barcode"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Date Expiration</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    type="date"
                                    value={expiryDate}
                                    onChange={(e) => { setExpiryDate(e.target.value) }}
                                    id="expiryDate"
                                    name="expiryDate"
                                    variant="outlined"
                                    sx={inputStyles}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Date Fabrication</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    type="date"
                                    value={fabricationDate}
                                    onChange={(e) => { setFabricationDate(e.target.value) }}
                                    id="fabricationDate"
                                    name="fabricationDate"
                                    variant="outlined"
                                    sx={inputStyles}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Date Entrée</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    type="date"
                                    value={entryDate}
                                    onChange={(e) => { setEntryDate(e.target.value) }}
                                    id="entryDate"
                                    name="entryDate"
                                    variant="outlined"
                                    sx={inputStyles}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Emplacement</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={location}
                                    onChange={(e) => { setLocation(e.target.value) }}
                                    id="location"
                                    name="location"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Produit</Typography>
                                <FormControl
                                    required
                                    fullWidth
                                    variant="outlined"
                                    sx={inputStyles}
                                >
                                    <Select
                                        labelId="product-label"
                                        id="product"
                                        value={product}
                                        onChange={(e) => setProduct(e.target.value)}
                                    >
                                        {products.map((product)=>(
                                            <MenuItem value={product.id_produits}>{product.nom_produits}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Quantité</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={Q}
                                    onChange={(e) => { setQ(e.target.value) }}
                                    id="location"
                                    type='number'
                                    name="location"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}
                            onClick={handlsubmit}
                            >
                                Ajouter
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default AddStock;
