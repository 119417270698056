import React, { useEffect, useState } from 'react'
import './styles/ClientPage.css';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import Navbar from './Navbar';
import axios from 'axios';
import CalendarPage from './CalendarPage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(147, 14, 14)',
        color: theme.palette.common.white,
        fontSize : 15.5,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: '1px solid',
    },
}));

function createData1(name) {
    return { name };
}

function createData2(observation, remarque) {
    return { observation, remarque };
}

const rows = [
    createData1('08 juillet 2024'),

];

const rows2 = [
    createData2('Observation', 'remarque'),


];




const ClientPage = () => {
    const [user , setuser] = useState('')
    const [next , setnext] = useState('')
    useEffect(()=>{
        const UserData = JSON.parse(localStorage.getItem("UserData"))
        setuser(UserData)
        console.log(UserData)
        axios.get('https://serv.pestima.yuliagroupe.com/api/conventions/next/'+UserData.conventions[0].id_Convention)
        .then((res)=>{
            console.log(res.data)
            setnext(res.data)
        })

    },[])

    return (
        <>
            <Navbar/>
            {/* Start conatainer */}
            <Box className='containerDash'>
                {/* Start Left Side */}
                <Box className='leftSide'>
                    <Box className='leftUp'>
                        <Box className="red-background">
                            <Box className='executedBy'>
                                <Box sx={{fontWeight:700 , fontSize:"25px"}}>Bienvenue Cher Client :</Box>
                                <Box>{user === '' ? null : user.personnes[0] ? user.personnes[0].Nom  : user.entreprises[0].Nom}</Box>
                            </Box>
                        </Box>
                        <Typography  sx={{fontWeigth : 'bold', color : 'rgb(147, 14, 14)',fontSize:"35px"}}>Prochaine Passage</Typography>
                        <Typography variant='h5' sx={{fontWeigth : 'bold', color : 'gray'}}>{next.nearest_future_passage ? next.nearest_future_passage.date_debut : "Aucun futur passage n'est planifié."}</Typography>
                    </Box>
                    <Box className='leftDown'>
                    <CalendarPage called={true}/>
                    </Box>
                </Box>
                <Box className='rightSide'>
                    <TableContainer component={Paper} sx={{width: "92%", maxHeight : "85vh"}}>
                        <Table sx={{width: "100%"}} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align='center'><Typography variant='body1'>Dernier Passage</Typography></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {next ? next.passages
                                    .filter((row) => new Date(row.date_debut) < new Date()) // Filter dates in the past
                                    .map((row) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell component="th" scope="row" sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                                                <Box sx={{width: '50%'}}>{row.date_debut}</Box>
                                                <Box><CheckIcon color='success' size='small'/></Box>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )) : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            {/* End conatainer */}
        </>
    )
}

export default ClientPage
