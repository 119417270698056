import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Modal
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const plans = [
  { id: 1, name: 'Plan 1', img: 'path_to_image1.jpg' },
  { id: 2, name: 'Plan 2', img: 'path_to_image2.jpg' },
  { id: 3, name: 'Plan 3', img: 'path_to_image3.jpg' },
  // Add more plans as needed
];

const inputStyles = {
  backgroundColor: '#f2f3f3',
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    height: '35px',
    '& fieldset': {
      borderColor: '#C0C0C0',
    },
    '&:hover fieldset': {
      borderColor: '#930e0e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#930e0e',
    },
  },
  '& label.Mui-focused': {
    color: '#930e0e',
    fontWeight: 'bold',
  },
};

function ListePlan() {
  const [Plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Conventions, setConventions] = useState([]);
  const [SelectedConventions, setSelectedConventions] = useState("");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const getallplan=()=>{
    axios.get("https://serv.pestima.yuliagroupe.com/api/plans")
    .then((res) => {
      setPlans(res.data);
    });
  }

  useEffect(() => {
    getallplan()
    axios.get("https://serv.pestima.yuliagroupe.com/api/conventions")
      .then((res) => {
        setConventions(res.data);
      });
  }, []);

  const handleFormSubmit = () => {
    if (SelectedConventions && file) {
      const formData = new FormData();
      formData.append('id_Convention', SelectedConventions);
      formData.append('img', file);

      axios.post("https://serv.pestima.yuliagroupe.com/api/plans", formData)
        .then((res) => {
          // Handle success, you might want to refresh the plans list or show a success message
          getallplan()
          console.log(res.data);
          setOpen(false);
        })
        .catch((err) => {
          // Handle error
          console.error(err);
        });
    }
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        <Box component="main" sx={{ flexGrow: 1, p: 1.5, overflowX: 'hidden' }}>
          <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom component="div" sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2 }}>
              Liste des Plans
            </Typography>
            <Button variant="contained" sx={{
              mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily: 'Roboto',
              '&:hover': { bgcolor: '#a9281e' },
              color: 'white',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '15%',
              alignSelf: 'flex-end'
            }}
              onClick={handleOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Ajouter Plan
            </Button>
            <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
              {Plans.map(plan => (
                <Card key={plan.id} sx={{ width: '400px', mb: 2, display: 'flex', bgcolor: '#fff', color: '#000' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 140 }}
                    image={"https://serv.pestima.yuliagroupe.com/api/plans/" + plan.id_plan}
                    alt={plan.name}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography variant="h6" component="div" sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', mb: 3 }}>
                        {plan.convention.client.entreprises[0] ? plan.convention.client.entreprises[0].Nom : plan.convention.client.personnes[0].Nom}
                      </Typography>
                      <Button variant="contained" sx={{
                        mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily: 'Roboto',
                        '&:hover': { bgcolor: '#a9281e' },
                        color: 'white',
                        textTransform: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '70%',
                        alignSelf: 'flex-end'
                      }}
                        onClick={() => navigate("/plan/"+plan.id_plan)}
                      >
                        Consulter
                      </Button>
                    </CardContent>
                  </Box>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Ajouter Plan
          </Typography>
          <Box id="modal-description" sx={{ mt: 2, display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
              <Button
                component="label"
                variant="contained"
                sx={{
                  mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily: 'Roboto',
                  '&:hover': { bgcolor: '#a9281e' },
                  color: 'white',
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: "130px"
                }}
              >
                Importer Plan
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setFile(e.target.files[0])}
                  hidden
                />
              </Button>
              {file && (
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {file.name}
                </Typography>
              )}
            </Box>
            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Conventions</Typography>
              <FormControl
                required
                fullWidth
                variant="outlined"
                sx={inputStyles}
              >
                <Select
                  labelId="product-label"
                  id="product"
                  value={SelectedConventions}
                  onChange={(e) => setSelectedConventions(e.target.value)}
                >
                  {Conventions.map((convention) => (
                    <MenuItem key={convention.id_Convention} value={convention.id_Convention}>{convention.client.entreprises[0] ? convention.client.entreprises[0].Nom : convention.client.personnes[0].Nom}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              sx={{
                mt: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily: 'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: "130px"
              }}
              onClick={handleFormSubmit}
            >
              Envoyer
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ListePlan;
