import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton,  Box, Menu, MenuItem, CssBaseline } from '@mui/material';
import PestimaLogo from './img/PestimaBlack.png';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useNavigate } from 'react-router-dom';

function Navbar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setAnchorEl(null); // Close the menu after action
  };



  const handleLogoutClick = () => {
    localStorage.setItem("isloged",false);
    localStorage.removeItem("UserData")
    setAnchorEl(null);
    navigate("/") // Close the menu after action
  };

  const bgColor = '#fff';
  const textColor = '#000';
  const fontFamily = 'Roboto, sans-serif';

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: bgColor }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={PestimaLogo} alt="PESTIMA Logo" style={{ height: '25px', marginRight: '10px' }} />
          </Box>

          {/* Centering the buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Button
              sx={{
                color: textColor,
                fontFamily: fontFamily,
                textTransform: 'none',
                fontWeight: 'bold',
                position: 'relative',
                mr: 2,
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: props.activeButton === 'Accueil' ? '100%' : 0,
                  height: '3px',
                  backgroundColor: '#930e0e',
                  transition: 'width 0.3s ease-in-out',
                },
              }}
              onClick={() => navigate('/Acceuil')}
            >
              Accueil
            </Button>

            <Button
              sx={{
                color: textColor,
                fontFamily: fontFamily,
                textTransform: 'none',
                fontWeight: 'bold',
                position: 'relative',
                mr: 2,
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: props.activeButton === 'Passages' ? '100%' : 0,
                  height: '3px',
                  backgroundColor: '#930e0e',
                  transition: 'width 0.3s ease-in-out',
                },
              }}
              onClick={() =>navigate('/Passages')}
            >
              Passages
            </Button>

            <Button
              sx={{
                color: textColor,
                fontFamily: fontFamily,
                textTransform: 'none',
                fontWeight: 'bold',
                position: 'relative',
                mr: 2,
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: props.activeButton === 'Mesures' ? '100%' : 0,
                  height: '3px',
                  backgroundColor: '#930e0e',
                  transition: 'width 0.3s ease-in-out',
                },
              }}
              onClick={() =>navigate('/Mesures')}
            >
              Mesures
            </Button>

            <Button
              sx={{
                color: textColor,
                fontFamily: fontFamily,
                textTransform: 'none',
                fontWeight: 'bold',
                position: 'relative',
                mr: 2,
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: props.activeButton === 'Statistiques' ? '100%' : 0,
                  height: '3px',
                  backgroundColor: '#930e0e',
                  transition: 'width 0.3s ease-in-out',
                },
              }}
              onClick={() => navigate('/Statictiques')}
            >
              Statistiques
            </Button>

            <Button
              sx={{
                color: textColor,
                fontFamily: fontFamily,
                textTransform: 'none',
                fontWeight: 'bold',
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: props.activeButton === 'Contacter Nous' ? '100%' : 0,
                  height: '3px',
                  backgroundColor: '#930e0e',
                  transition: 'width 0.3s ease-in-out',
                },
              }}
              onClick={() => navigate('/ContactUs')}
            >
              Contacter Nous
            </Button>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleAvatarClick} size="small" sx={{ ml: 2 }}>
              <PersonOutlineIcon sx={{ width: 40, height: 40 }} />
            </IconButton>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleAvatarClose}
            >
              <MenuItem onClick={handleLogoutClick}>Log Out</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 3, zIndex: 1 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

export default Navbar;
