import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';


const ButtonStyles = {
    mb: 2,
    bgcolor: '#930E0E',
    borderRadius: '5px',
    fontFamily: 'Roboto',
    '&:hover': { bgcolor: '#a9281e' },
    color: 'white',
    textTransform: 'none',
    width: '25%',
};


const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <Typography>Êtes-vous sûr des informations? Elles ne sont pas modifiables.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={ButtonStyles}>Annuler</Button>
        <Button onClick={onConfirm} sx={ButtonStyles}>Confirmer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
