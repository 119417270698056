import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  Box,
  IconButton,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import Navbar from './Navbar.js';

const data = [
  { id: 1, nom: 'Document A' },
  { id: 2, nom: 'Document B' },
  // Add more data as needed
];

function DocumentClientTable() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDelete = () => {
    console.log('Deleting', selectedRow);
    handleClose();
  };

  const handleEdit = () => {
    console.log('Editing', selectedRow);
    handleClose();
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        <Box component="main" sx={{ flexGrow: 1, p: 1.5, overflowX: 'hidden' }}>
          <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2 }}
            >
              Documents Clients
            </Typography>
            <TextField
              id="search-documents"
              type="search"
              variant="outlined"
              placeholder="Search Document..."
              sx={{
                mb: 2,
                fontFamily: 'Arial, sans-serif',
                width: '100%',
                borderRadius: '9px',
                backgroundColor: '#f2f3f3',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '9px',
                  '&:hover fieldset': {
                    borderColor: '#930E0E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#930E0E',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TableContainer sx={{ width: '100%', mb: 2, borderRadius: '9px', border: '1px solid #C0C0C0' }}>
              <Table sx={{ width: '100%', border: '1px solid #C0C0C0', fontFamily: 'Roboto' }} aria-label="documents table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        border: '1px solid #dbd8e3',
                        padding: '8px',
                        color: '#000000',
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: 'bold',
                        backgroundColor: '#F2F2F2',
                      }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      style={{
                        border: '1px solid #dbd8e3',
                        padding: '8px',
                        color: '#000000',
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: 'bold',
                        backgroundColor: '#F2F2F2',
                      }}
                    >
                      Nom Document
                    </TableCell>
                    <TableCell
                      style={{
                        border: '1px solid #dbd8e3',
                        padding: '8px',
                        color: '#000000',
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: 'bold',
                        backgroundColor: '#F2F2F2',
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.id}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.nom}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClick(event, row)}>
                          <FileDownloadIcon sx={{ color: 'red' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              sx={{
                mb: 2,
                bgcolor: '#930E0E',
                borderRadius: '9px',
                fontFamily: 'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '15%',
                alignSelf: 'flex-end',
              }}
            >
              <AddIcon sx={{ mr: 1 }} />
              Ajouter Nouveau
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DocumentClientTable;
