import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Checkbox,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import divider from './img/divider.png'
import cacher from "./img/cacher.png"

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


const data = [
  { id: 1, codeRef: 'ABC123', emplacement: 'Zone 1', actif: true, observations: 'No issues', interne: false, dateAbendement: '2024-12-01' },
  { id: 2, codeRef: 'XYZ789', emplacement: 'Zone 2', actif: false, observations: 'Needs maintenance', interne: true, dateAbendement: '2025-06-15' },
  // Add more data as needed
];

function PassageTable() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data,setdata] = useState([])
  const navigate = useNavigate()
  const certificateRef = useRef();

  const [selectedDesinsectisations, setselectedDesinsectisations] = useState([]);
  const [selectedDeratisations, setselectedDeratisation] = useState([]);
  const [selectedDeraptilisation, setselectedDeraptilisation] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const formatDate = (date = new Date()) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const getPosts = ()=>{
    axios.get("https://serv.pestima.yuliagroupe.com/api/passages")
    .then((res)=>{
      setdata(res.data)
    })
  }

  useEffect(()=>{
    getPosts()
  },[])


  

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    console.log(row);
    setSelectedRow(row);

    const deratisations = row.produits_a_utilisees.filter(p => p.produit.ORGANISME_CIBLE === 'Dératisation');
    const desinsectisations = row.produits_a_utilisees.filter(p => p.produit.ORGANISME_CIBLE === 'Désinsectisation');
    const deraptilisations = row.produits_a_utilisees.filter(p => p.produit.ORGANISME_CIBLE === 'Déraptilisation');
    setselectedDeratisation(deratisations)
    console.log(deratisations)

  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDelete = () => {
    console.log('Deleting', selectedRow);
    handleClose();
  };

  const handleEdit = () => {
    console.log('Editing', selectedRow);
    handleClose();
  };

  const downloadPdf = () => {
    const input = certificateRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [210, 297], // A4 size in landscape
      });
      pdf.addImage(imgData, 'PNG', 0, 0, 297, 210);
      pdf.save('certificate.pdf');
    });
  };

  const today = new Date();
  const formattedDate = formatDate(today);


  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        <Box component="main" sx={{ flexGrow: 1, p: 1.5, overflowX: 'hidden' }}>
          <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom component="div" sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2 }}>
              Passages
            </Typography>
            <TextField
              id="search-piege"
              type="search"
              variant="outlined"
              placeholder="Search Posts..."
              sx={{
                mb: 2,
                fontFamily: 'Arial, sans-serif',
                width: '100%',
                borderRadius: '9px',
                backgroundColor: '#f2f3f3',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '9px',
                  '&:hover fieldset': {
                    borderColor: '#930E0E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#930E0E',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TableContainer sx={{ width: '100%', mb: 2, borderRadius: '9px', border: '1px solid #C0C0C0' }}>
              <Table sx={{ width: '100%', border: '1px solid #C0C0C0', fontFamily: 'Roboto' }} aria-label="piege table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>date debut</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>date fin</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Validée</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.date_debut}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.date_fin}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>
                        <Checkbox checked={row.ended} disabled 
                         sx={{ color: '#930e0e',
                            '&.Mui-checked': {
                              color: '#930e0e',
                            },
                        }}/>
                      </TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, row)}
                        >
                          <ArrowDropDownIcon sx={{ color: 'red' }} />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)&& selectedRow?.id_passage === row.id_passage}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleEdit}>certificat</MenuItem>
                          <MenuItem onClick={()=>navigate('/fcp/'+row.id_passage)}>fcp</MenuItem>
                          <MenuItem onClick={()=>navigate("/fct/"+row.id_passage)}>fct</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>


      <div
  ref={certificateRef}
  id='containerpdf'
  style={{
    padding: '20px',
    width: '297mm',
    height: '210mm',
    border: '1px solid #ddd',
    marginBottom: '20px',
    boxSizing: 'border-box',
    backgroundImage: 'url(./bg.png)',
    backgroundSize: 'contain',

  }}
>
  <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '25px' }}>
    <div style={{ marginLeft: '50px', display: 'flex' }}>
      <img src='/logo.png' alt='Logo' style={{ height: '60px' }} />
    </div>
    <h1 style={{fontFamily:"Yeseva One" , fontSize:42 , marginBottom:"0px"}}>ATTESTATION</h1>
    <p style={{fontFamily:"Open Sans"}}>
      Nous soussignés, société <b>PESTIMA</b> certifions que<br />
      Le traitement de dératisation, désinsectisation et déreptilisation a été effectué dans l'ensemble des locaux de :
    </p>
    <h2 style={{fontFamily:"Yeseva One" , color:"#C00000" , fontSize:26 , margin:"5px 0px"}}>{selectedEvent ? selectedEvent.title : null}</h2>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ display: 'flex', justifyContent: 'space-around', width: '90%' ,columnGap:"200px" }}>
      <div style={{ flex: '1', textAlign: 'center' }}>
        <h3 style={{marginRight:"70px" , marginBottom:"0px", marginTop:"10px",fontFamily:"Open Sans"}}>DERATISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' }}>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454" , fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedDeratisations.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.produit.nom_produits}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 

          }
          
          
        </div>
      </div>
      <div style={{ flex: '1', textAlign: 'center' }}>
        <h3 style={{marginRight:"50px" , marginBottom:"0px",marginTop:"10px" ,fontFamily:"Open Sans"}}>DESINSECTISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' }}>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedEvent ? selectedDesinsectisations.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.produit.nom_produits}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
        </div>
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
      <div style={{ textAlign: 'center' }}>
        <h3 style={{marginRight:"20px" , marginBottom:"0px" , fontFamily:"Open Sans" , marginTop:2}}>DERAPTILISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' , columnGap:"80px" }}>
          <p style={{margin:"0px" , marginBottom:"2px" , fontFamily:"Carlito", color:"#545454", fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedEvent ? selectedDeraptilisation.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
        </div>
      </div>
    </div>
  </div>
  <div style={{ textAlign: 'center', marginTop: '10px' }}>
    <p style={{margin:"0px" , fontWeight:"700"}}>Ce certificat est délivré pour servir et valoir ce que de droit.</p>
    <img src={divider} width="60px"/>
  </div>
  <div style={{display:"flex" , flexDirection:"column" , alignItems:"center" , position:"absolute" , right:"100px" , top:"600px"}}>
  <p style={{margin: "0px",fontFamily:"Open Sans" , fontSize:14}}><b style={{color:"#C00000"}}>FAIT À :</b> {`CASABLANCA`} <b style={{color:"#C00000"}}>LE : </b>{formattedDate}</p>
  <p style={{margin:"0px",fontFamily:"Open Sans" , color:"#808080",fontSize:14 , fontWeight:700}}>DIRECTION </p>
  <img src={cacher} style={{marginTop:"5px"}}/>
  </div>
</div>




    </Box>
  );
}

export default PassageTable;
