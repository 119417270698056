import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, Button, TableRow, Checkbox, TextField, Paper, Select, MenuItem, FormControl, InputLabel, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Navbar from './Navbar.js';

const ButtonStyles = {
  mb: 2,
  mt: 2,
  bgcolor: '#930E0E',
  borderRadius: '5px',
  fontFamily: 'Roboto',
  '&:hover': { bgcolor: '#a9281e' },
  color: 'white',
  textTransform: 'none',
  width: '15%',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: '#930e0e',
  '&.Mui-checked': {
    color: '#930e0e',
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#930e0e',
    },
    '&:hover fieldset': {
      borderColor: '#930e0e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#930e0e',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#930e0e',
    '&.Mui-focused': {
      color: '#930e0e',
    },
  },
  '& .MuiSelect-root': {
    '& fieldset': {
      borderColor: '#930e0e',
    },
  },
  width: '100%',
  maxWidth: '400px',
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#930e0e',
    },
    '&:hover fieldset': {
      borderColor: '#930e0e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#930e0e',
    },
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff',
  color: "#930e0e",
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? theme.palette.action.hover : theme.palette.background.paper,
}));

const createData = (interne, d, in1, hu, rc, sp, observations, externe, t, d2, in2, co, sp2, observations2) => {
  return { interne, d, in1, hu, rc, sp, observations, externe, t, d2, in2, co, sp2, observations2 };
}

const initialRows = [
  createData(1, false, false, false, false, false, '', 1, false, false, false, false, true, ''),
  createData(2, false, false, false, false, false, '', 2, false, false, false, false, true, ''),
  // Add more rows as needed
];

const DataTable = () => {
  const [selectedTable, setSelectedTable] = useState('INTERNE');
  const [rows, setRows] = useState(initialRows);

  const handleChange = (event) => {
    setSelectedTable(event.target.value);
  };

  const handleCheckboxChange = (index, key) => (event) => {
    const newRows = [...rows];
    newRows[index][key] = event.target.checked;
    setRows(newRows);
  };

  const handleTextFieldChange = (index, key) => (event) => {
    const newRows = [...rows];
    newRows[index][key] = event.target.value;
    setRows(newRows);
  };

  return (
    <Box>
      <Navbar />
      <Box m={2}>
      <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2, ml: 2, width: '40%' }}
        >Controle Page</Typography>
        <Box mb={2}>
          <CustomFormControl variant="outlined" margin="normal">
            <InputLabel id="table-select-label">Select Table</InputLabel>
            <Select
              labelId="table-select-label"
              value={selectedTable}
              onChange={handleChange}
              label="Select Table"
            >
              <MenuItem value="INTERNE">Interne</MenuItem>
              <MenuItem value="EXTERNE">Externe</MenuItem>
            </Select>
          </CustomFormControl>
        </Box>

        {selectedTable === 'INTERNE' && (
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Interne</StyledTableCell>
                  <StyledTableCell>N°</StyledTableCell>
                  <StyledTableCell>D</StyledTableCell>
                  <StyledTableCell>IN</StyledTableCell>
                  <StyledTableCell>HU</StyledTableCell>
                  <StyledTableCell>RC</StyledTableCell>
                  <StyledTableCell>SP</StyledTableCell>
                  <StyledTableCell>OBSERVATIONS</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={index} index={index}>
                    <TableCell>{row.interne}</TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell><CustomCheckbox checked={row.d} onChange={handleCheckboxChange(index, 'd')} /></TableCell>
                    <TableCell><CustomCheckbox checked={row.in1} onChange={handleCheckboxChange(index, 'in1')} /></TableCell>
                    <TableCell><CustomCheckbox checked={row.hu} onChange={handleCheckboxChange(index, 'hu')} /></TableCell>
                    <TableCell><CustomCheckbox checked={row.rc} onChange={handleCheckboxChange(index, 'rc')} /></TableCell>
                    <TableCell><CustomCheckbox checked={row.sp} onChange={handleCheckboxChange(index, 'sp')} /></TableCell>
                    <TableCell><CustomTextField value={row.observations} onChange={handleTextFieldChange(index, 'observations')} /></TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}

        {selectedTable === 'EXTERNE' && (
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Externe</StyledTableCell>
                  <StyledTableCell>N°</StyledTableCell>
                  <StyledTableCell>T</StyledTableCell>
                  <StyledTableCell>D</StyledTableCell>
                  <StyledTableCell>IN</StyledTableCell>
                  <StyledTableCell>CO</StyledTableCell>
                  <StyledTableCell>SP</StyledTableCell>
                  <StyledTableCell>OBSERVATIONS</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={index} index={index}>
                    <TableCell>{row.externe}</TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell><CustomCheckbox checked={row.t} onChange={handleCheckboxChange(index, 't')} /></TableCell>
                    <TableCell><CustomCheckbox checked={row.d2} onChange={handleCheckboxChange(index, 'd2')} /></TableCell>
                    <TableCell><CustomCheckbox checked={row.in2} onChange={handleCheckboxChange(index, 'in2')} /></TableCell>
                    <TableCell><CustomCheckbox checked={row.co} onChange={handleCheckboxChange(index, 'co')} /></TableCell>
                    <TableCell><CustomCheckbox checked={row.sp2} onChange={handleCheckboxChange(index, 'sp2')} /></TableCell>
                    <TableCell><CustomTextField value={row.observations2} onChange={handleTextFieldChange(index, 'observations2')} /></TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
        <Button variant="contained" color="primary" sx={ButtonStyles}>
          Confirmer
        </Button>
      </Box>
    </Box>
  );
}

export default DataTable;




