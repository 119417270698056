import React, { useState } from 'react';
import { Box, TextField, Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import Navbar from './Navbar.js';

function AddPost() {
    const [codeRef, setCodeRef] = useState('');
    const [emplacement, setEmplacement] = useState('');
    const [actif, setActif] = useState(false);
    const [observations, setObservations] = useState('');
    const [interne, setInterne] = useState(false);
    const [dateAbendement, setDateAbendement] = useState('');

    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };

    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Ajouter Post</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Code Référence</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={codeRef}
                                    onChange={(e) => { setCodeRef(e.target.value) }}
                                    id="codeRef"
                                    name="codeRef"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Emplacement</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={emplacement}
                                    onChange={(e) => { setEmplacement(e.target.value) }}
                                    id="emplacement"
                                    name="emplacement"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Observations</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={observations}
                                    onChange={(e) => { setObservations(e.target.value) }}
                                    id="observations"
                                    name="observations"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Date Abonnement</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    type="date"
                                    value={dateAbendement}
                                    onChange={(e) => { setDateAbendement(e.target.value) }}
                                    id="dateAbendement"
                                    name="dateAbendement"
                                    variant="outlined"
                                    sx={inputStyles}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={actif}
                                            onChange={(e) => { setActif(e.target.checked) }}
                                            name="actif"
                                            sx={{ color: '#930e0e',
                                                '&.Mui-checked': {
                                                  color: '#930e0e',
                                                },
                                            }}
                                        />
                                    }
                                    label="Actif"
                                />
                            </Box>
                            
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={interne}
                                            onChange={(e) => { setInterne(e.target.checked) }}
                                            name="interne"
                                            sx={{ color: '#930e0e',
                                                '&.Mui-checked': {
                                                  color: '#930e0e',
                                                },
                                            }}
                                        />
                                    }
                                    label="Interne"
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}>
                                Ajouter
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default AddPost;
