import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Button, Select, MenuItem, FormControl, Snackbar, Alert  } from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate , useParams} from 'react-router-dom';

function UpdateClient() {
    const [type, setType] = useState('1');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [ice, setIce] = useState('');
    const [open ,setOpen] = useState(false)
    const [opener ,setOpener] = useState(false)
    const [message , setmessage ] = useState("")
    const {id} = useParams()
    const navigate = useNavigate()

    const getclient=()=>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/clients/"+id)
        .then((res)=>{
            setNom(res.data.Nom)
            setPrenom(res.data.Prenom)
            setEmail(res.data.email)
            setLogin(res.data.Login)
            setType(res.data.type_client_id)
        })
    }
    useEffect(()=>{
        getclient()
    },[])
    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };

    const handlsubmit=()=>{
        let data ;
        if(prenom !=="" && password !==""){
             data = {
                email:email,
                Password:password,
                Nom:nom,
                Prenom:prenom,
                Login:login,
                type_client_id:type
            }
        }else if(prenom ==="" && password !==""){
             data = {
                email:email,
                Password:password,
                Nom:nom,
                Prenom:ice,
                Login:login,
                type_client_id:type
            }
        }else if(prenom ==="" && password ===""){
            data = {
               email:email,
               Nom:nom,
               Prenom:ice,
               Login:login,
               type_client_id:type
           }
       }else if(prenom !=="" && password ===""){
        data = {
           email:email,
           Nom:nom,
           Prenom:prenom,
           Login:login,
           type_client_id:type
       }
   }
        axios.put("https://serv.pestima.yuliagroupe.com/api/clients/"+id,data)
        .then((res)=>{
            if(res.data.error){
                setmessage(res.data.message)
                setOpener(true)
            }else{
                setmessage(res.data.message)
                setOpen(true)
                setTimeout(() => {
                    navigate("/ClientTable")
                }, 3000);
            }
        })
    }


    const handleTypeChange = (event) => {
        setType(event.target.value);
        setNom('');
        setPrenom('');
        setIce('');
    };

    const handleCloseer = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpener(false);
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    

    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Modifier Client</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Type</Typography>
                                <FormControl
                                    required
                                    fullWidth
                                    variant="outlined"
                                    sx={inputStyles}
                                >
                                    <Select
                                        labelId="type-label"
                                        id="type"
                                        value={type}
                                        onChange={handleTypeChange}
                                    >
                                        <MenuItem value="1">Entreprise</MenuItem>
                                        <MenuItem value="2">Personnel</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                           
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Login</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='new-login'
                                    value={login}
                                    onChange={(e) => { setLogin(e.target.value) }}
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            {type === '1' ? (
                                <>
                                    <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Nom d'Entreprise</Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            value={nom}
                                            onChange={(e) => { setNom(e.target.value) }}
                                            id="NomEntreprise"
                                            name="NomEntreprise"
                                            variant="outlined"
                                            sx={inputStyles}
                                        />
                                    </Box>
                                    <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>ICE</Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            value={ice}
                                            onChange={(e) => { setIce(e.target.value) }}
                                            id="ice"
                                            name="ice"
                                            variant="outlined"
                                            sx={inputStyles}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Nom de Client</Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            value={nom}
                                            onChange={(e) => { setNom(e.target.value) }}
                                            id="Nom"
                                            name="Nom"
                                            variant="outlined"
                                            sx={inputStyles}
                                        />
                                    </Box>
                                    <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Prenom de Client</Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            value={prenom}
                                            onChange={(e) => { setPrenom(e.target.value) }}
                                            id="prenom"
                                            name="prenom"
                                            variant="outlined"
                                            sx={inputStyles}
                                        />
                                    </Box>
                                </>
                            )}
                              <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Password</Typography>
                                <TextField
                                    type='password'
                                    required
                                    fullWidth
                                    value={password}
                                    autoComplete="new-password"
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}
                            onClick={handlsubmit}
                            >
                                Modifier
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default UpdateClient;
