import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Avatar, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Navbar from './Navbar.js';

function UserSetting() {
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };

    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Paramètres Utilisateur</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: 2,
                                width: '100%',
                            }}
                        >
                            <Avatar
                                alt="User Avatar"
                                src="/path-to-your-image.jpg"
                                sx={{ width: 68, height: 68, mr: 2 }}
                            />
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
                                    Role
                                </Typography>
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                    User Name
                                    <IconButton size="large" sx={{ color: '#930e0e' }}>
                                        <EditIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Nom</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nom}
                                    onChange={(e) => { setNom(e.target.value) }}
                                    id="nom"
                                    name="nom"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Prénom</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={prenom}
                                    onChange={(e) => { setPrenom(e.target.value) }}
                                    id="prenom"
                                    name="prenom"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Login</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={login}
                                    onChange={(e) => { setLogin(e.target.value) }}
                                    id="login"
                                    name="login"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Mot de passe</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    type="password"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    id="password"
                                    name="password"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}>
                                Sauvegarder
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default UserSetting;
