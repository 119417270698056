import React, { useState } from 'react';
import Navbar from './Navbar.js';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr'; 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Button, Toolbar, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

moment.locale('fr');
const localizer = momentLocalizer(moment);


const eventsData = [
    {
        id: 1,
        title: 'Révision',
        description: 'Révision du véhicule',
        start: new Date(2024, 4, 25, 10, 0),
        end: new Date(2024, 4, 25, 12, 0),
    },
    {
        id: 2,
        title: 'Maintenance',
        description: 'Maintenance préventive',
        start: new Date(2024, 4, 27, 14, 0),
        end: new Date(2024, 4, 27, 16, 0),
    },
];

function DashboardClient() {
    const [date, setDate] = useState(new Date());
    const [view, setView] = useState('month');

    const handleNavigate = (action) => {
        setDate(action);
    };

    const handleViewChange = (view) => {
        setView(view);
    };

    const CustomEvent = ({ event }) => (
        <span>{event.title}</span>
    );

    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h5" gutterBottom component="div" sx={{ fontFamily: 'Roboto, sans-serif', width: '100%', mb: 2, fontWeight: 'bold' }}>
                            Dashboard Client
                        </Typography>
                        <Box sx={{ width: '100%', mx: "auto" }}>
                            <Toolbar>
                                <Button startIcon={<ArrowBackIos sx={{ color: '#930E0E' }} />} onClick={() => handleNavigate(moment(date).add(-1, view).toDate())} />
                                <Button endIcon={<ArrowForwardIos sx={{ color: '#930E0E' }} />} onClick={() => handleNavigate(moment(date).add(1, view).toDate())} />
                                <Typography variant="h4" sx={{ flexGrow: 1, textAlign: 'center' }}>
                                    {moment(date).format('MMMM YYYY')}
                                </Typography>
                            </Toolbar>
                           
                            <Calendar
                                localizer={localizer}
                                events={eventsData}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                                date={date}
                                onNavigate={handleNavigate}
                                onView={handleViewChange}
                                view={view}
                                components={{ event: CustomEvent }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default DashboardClient;
