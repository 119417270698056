import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  Button,
  Grid,
  Container,
  Box,
  Chip,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Navbar from './NavbarC.js';
import Footer from './FooterC.js';
import axios from 'axios';

const initialMeasures = [
  { id: 1, passage: 'Entrance', description: 'Install hand sanitizer stations', isSolved: false },
  { id: 2, passage: 'Main Hall', description: 'Implement social distancing markers', isSolved: true },
  { id: 3, passage: 'Cafeteria', description: 'Increase ventilation', isSolved: true },
  { id: 4, passage: 'Office Area', description: 'Provide personal protective equipment', isSolved: false },
  { id: 5, passage: 'Meeting Rooms', description: 'Limit occupancy to 50%', isSolved: true },
];

function PreventiveMeasures() {
  const [measures, setMeasures] = useState(initialMeasures);

  const handleSolve = (id) => {

        axios.put('https://serv.pestima.yuliagroupe.com/api/questions/'+id,{Response:1 , Remarque : "" })
        .then((res)=>{
          getmesure()
        })

  };
  
  const getmesure=()=>{
    axios.get("https://serv.pestima.yuliagroupe.com/api/clients/"+JSON.parse(localStorage.getItem('UserData')).id_client+"/audits")
    .then((res)=>{
      const transformedData = res.data.map(audit => 
        audit.categorie.flatMap(category => 
          category.questions.map(question => ({
            id: question.ID_Question,
            passage: category.Category,  // Category becomes 'passage'
            description: question.Question,  // Question becomes 'description'
            isSolved: parseInt(question.Response)  // Response becomes 'isSolved'
          }))
        )
      );
      // Flatten the array and set the state
      setMeasures(transformedData.flat());
    })
  }

  useEffect(()=>{
    getmesure()
  },[])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Navbar activeButton={"Mesures"}/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 ,mb:"50px"}}>
    <Grid container spacing={2} alignItems="stretch">
        {measures.map((measure) => (
          <Grid item xs={12} sm={6} md={4} key={measure.id} sx={{ display: 'flex' }}>
            <Card
              sx={{
                flex: 1, // Ensures all cards take equal space
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxShadow: 3,
                bgcolor: parseInt(measure.isSolved) ? '#e6f4ea' : '#fdecea',
                border: parseInt(measure.isSolved) ? '1px solid #c3e6cb' : '1px solid #f5c6cb',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                    {measure.passage}
                  </Typography>
                }
                action={
                  <Chip
                    label={parseInt(measure.isSolved) ? 'Résolu' : 'Non Résolu'}
                    icon={parseInt(measure.isSolved) ? <CheckCircleOutlineIcon fontSize="small" /> : <ErrorOutline fontSize="small" />}
                    variant="outlined"
                    sx={{
                      borderRadius: '12px', // More rounded for a compact look
                      height: 22, // Smaller height
                      fontSize: '0.6rem', // Smaller font size
                      fontWeight: 'bold', // Bold text for better visibility
                      backgroundColor: measure.isSolved ? 'transparent' : '#f44336',
                      color: measure.isSolved ? 'black' : '#fff',
                      borderColor: measure.isSolved ? '240 5.9% 90%' : 'transparent', // Border for green chip
                      '& .MuiChip-icon': {
                        color:parseInt(measure.isSolved) ? 'black' : '#fff', // Icon color matching text color
                      },
                    }}
                  />
                }
              />
              <CardContent>
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                  {measure.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', padding: 1 }}>
                {parseInt(measure.isSolved) ? (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#2e7d32',
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    Mesure résolue
                  </Typography>
                ) : (
                  <Button
                    onClick={() => handleSolve(measure.id)}
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: '#00897b',
                      '&:hover': { bgcolor: '#00796b' },
                      color: '#fff',
                    }}
                  >
                    Marquer comme résolu
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    <Footer />
</Box>
  );
}

export default PreventiveMeasures;
