import React, { useState } from 'react';
import { Box, TextField, Typography, Button, FormControl, Select, MenuItem , Alert , Snackbar } from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AddClientPersonne() {
    const [nom, setNom] = useState('');
    const [numeroTelephone, setNumeroTelephone] = useState('');
    const [frequenceIntervention, setFrequenceIntervention] = useState('');
    const [adresse, setAdresse] = useState('');
    const [preferenceContact, setPreferenceContact] = useState('');
    const [typeNuisible, setTypeNuisible] = useState('');
    const [login, setLogin] = useState('');
    const [email, setemail] = useState('');
    const [password, setPassword] = useState('');

    const [open ,setOpen] = useState(false)
    const [opener ,setOpener] = useState(false)
    const [message , setmessage ] = useState("")

    const navigate = useNavigate()
    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };


    const handleCloseer = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpener(false);
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };


    const handleSubmit = () =>{
        let dataClient = {
            login : login ,
            password : password,
            Email : email
        }
        axios.post('https://serv.pestima.yuliagroupe.com/api/clients',dataClient)
        .then((res)=>{
            if(!res.data.error){
                let dataPersonnes = {
                    id_client : res.data.client.id_client,
                    Nom : nom ,
                    Tel : numeroTelephone ,
                    Adresse : adresse ,
                    Type_Nuisible : typeNuisible ,
                    Frequence_intervention : frequenceIntervention ,
                    Preference_Contact : preferenceContact
                }
                axios.post('https://serv.pestima.yuliagroupe.com/api/personnes',dataPersonnes)
                .then((res)=>{
                    if(!res.data.error){
                        setmessage(res.data.message)
                        setOpen(true)
                        setTimeout(() => {
                            navigate('/ClientPersonneTable')
                        }, 3000);
                    }else{
                        setmessage(res.data.message)
                        setOpener(true)
                    }

                })
            }else{
                setmessage(res.data.message)
                setOpener(true)
            }

        })
    }



    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Ajouter Client Personnel</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Nom de Client</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nom}
                                    onChange={(e) => { setNom(e.target.value) }}
                                    id="nom"
                                    name="nom"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={email}
                                    onChange={(e) => { setemail(e.target.value) }}
                                    id="nom"
                                    name="new_email"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Adresse</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={adresse}
                                    onChange={(e) => { setAdresse(e.target.value) }}
                                    id="adresse"
                                    name="adresse"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Numéro de Téléphone</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    value={numeroTelephone}
                                    onChange={(e) => { setNumeroTelephone(e.target.value) }}
                                    id="numeroTelephone"
                                    name="numeroTelephone"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Fréquence Intervention</Typography>
                                <FormControl
                                    required
                                    fullWidth
                                    variant="outlined"
                                    sx={inputStyles}
                                >
                                    <Select
                                        labelId="frequenceIntervention-label"
                                        id="frequenceIntervention"
                                        value={frequenceIntervention}
                                        onChange={(e) => setFrequenceIntervention(e.target.value)}
                                    >
                                        <MenuItem value="Hebdomadaire">Hebdomadaire</MenuItem>
                                        <MenuItem value="Mensuelle">Mensuelle</MenuItem>
                                        <MenuItem value="Trimestrielle">Trimestrielle</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Préférence de Contact</Typography>
                                <FormControl
                                    required
                                    fullWidth
                                    variant="outlined"
                                    sx={inputStyles}
                                >
                                    <Select
                                        labelId="preferenceContact-label"
                                        id="preferenceContact"
                                        value={preferenceContact}
                                        onChange={(e) => setPreferenceContact(e.target.value)}
                                    >
                                        <MenuItem value="Email">Email</MenuItem>
                                        <MenuItem value="Téléphone">Téléphone</MenuItem>
                                        <MenuItem value="SMS">SMS</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Type de Nuisible</Typography>
                                <FormControl
                                    required
                                    fullWidth
                                    variant="outlined"
                                    sx={inputStyles}
                                >
                                    <Select
                                        labelId="frequenceIntervention-label"
                                        id="frequenceIntervention"
                                        value={typeNuisible}
                                        onChange={(e) => setTypeNuisible(e.target.value)}
                                    >
                                        <MenuItem value="Hebdomadaire">Dératisation</MenuItem>
                                        <MenuItem value="Mensuelle">Désinsectisation</MenuItem>
                                        <MenuItem value="Trimestrielle">Déreptilisation</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Login</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={login}
                                    onChange={(e) => { setLogin(e.target.value) }}
                                    id="login"
                                    name="new_login"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Password</Typography>
                                <TextField
                                    type='password'
                                    required
                                    fullWidth
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    id="password"
                                    name="new_password"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}
                            onClick={handleSubmit}
                            >
                                Ajouter
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>


        </Box>
    );
}

export default AddClientPersonne;
