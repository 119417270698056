import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Snackbar, Alert } from '@mui/material';
import SendIcon from '@mui/icons-material/Send'; // Import send icon
import Navbar from './NavbarC.js';
import Footer from './FooterC.js';
import FormLogo from './img/Contact.png';
import axios from 'axios';

function ContactUs() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('https://serv.pestima.yuliagroupe.com/api/contact', { name, email, subject, message });
          setSuccess(response.data.message);
          setError(null);
          setName('');
          setEmail('');
          setSubject('');
          setMessage('');
          setOpen(true)
        } catch (err) {
          setError('Failed to send the message. Please try again.');
          setOpener(true)
          setSuccess(null);
        }
      };

      const [open ,setOpen] = useState(false)
      const [opener ,setOpener] = useState(false)


      const handleCloseer = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpener(false);
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar activeButton={"Contacter Nous"}/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Box
                    sx={{
                        display: 'flex',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            width: '90%',
                            height: '70%',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            borderRadius: '10px',
                            boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
                            overflow: 'hidden',
                            p: 4,
                        }}
                    >

                        <Box
                            component="img"
                            src={FormLogo}
                            alt="Workflow"
                            sx={{
                                width: '50%',
                                objectFit: 'cover',
                                p: 3,
                            }}
                        />
                        
                        <Box
                            sx={{
                                width: '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 4,
                            }}
                        >
                            <Box sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                borderRadius: '20px',
                                boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
                                p: 2,
                                width: '100%', // Make sure this box takes full width
                            }}>
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <TextField
                  fullWidth
                  variant="outlined"
                  label="Nom*"
                  margin="normal"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    backgroundColor: '#f5f5f5',
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'transparent' },
                      '&:hover fieldset': { borderColor: 'transparent' },
                      '&.Mui-focused fieldset': { borderColor: 'transparent' },
                    },
                    '& label.Mui-focused': { color: '#930e0e' },
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="E-mail*"
                  margin="normal"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    backgroundColor: '#f5f5f5',
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'transparent' },
                      '&:hover fieldset': { borderColor: 'transparent' },
                      '&.Mui-focused fieldset': { borderColor: 'transparent' },
                    },
                    '& label.Mui-focused': { color: '#930e0e' },
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Sujet*"
                  margin="normal"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  sx={{
                    backgroundColor: '#f5f5f5',
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'transparent' },
                      '&:hover fieldset': { borderColor: 'transparent' },
                      '&.Mui-focused fieldset': { borderColor: 'transparent' },
                    },
                    '& label.Mui-focused': { color: '#930e0e' },
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Message*"
                  multiline
                  rows={4}
                  margin="normal"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  sx={{
                    backgroundColor: '#f5f5f5',
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'transparent' },
                      '&:hover fieldset': { borderColor: 'transparent' },
                      '&.Mui-focused fieldset': { borderColor: 'transparent' },
                    },
                    '& label.Mui-focused': { color: '#930e0e' },
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: '#930e0e',
                      color: '#fff',
                      borderRadius: '10px',
                      padding: '10px 20px',
                      textTransform: 'none',
                      '&:hover': { backgroundColor: '#930e0e' },
                    }}
                    endIcon={<SendIcon />}
                  >
                    Envoyer
                  </Button>
                </Box>
              </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />



            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {error}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {success}
                </Alert>
            </Snackbar>


        </Box>
    );
}

export default ContactUs;
