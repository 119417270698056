import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Button, Snackbar, Alert} from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

function UpdateClientEntreprise() {
    const [nom, setNom] = useState('');
    const [adresse, setAdresse] = useState('');
    const [ice, setIce] = useState('');
    const [nomContactPrincipal, setNomContactPrincipal] = useState('');
    const [fonctionContactPrincipal, setFonctionContactPrincipal] = useState('');
    const [telEnt, setTelEnt] = useState('');
    const [telConPrin, setTelConPrin] = useState('');
    const [emailEnt, setEmailEnt] = useState('');
    const [secteurActivite, setSecteurActivite] = useState('');
    const [typeNuisible, setTypeNuisible] = useState('');
    const [frequenceIntervention, setFrequenceIntervention] = useState('');
    const [preferenceContact, setPreferenceContact] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate()

    const [ IdEnt ,setIdEnt ] = useState("")

    const [open ,setOpen] = useState(false)
    const [opener ,setOpener] = useState(false)
    const [message , setmessage ] = useState("")


    const {id} = useParams()

    useEffect(()=>{
        getEntreprise()
    },[])

    const getEntreprise=()=>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/clients/"+id)
        .then((res)=>{
            setNom(res.data.entreprises[0].Nom)
            setAdresse(res.data.entreprises[0].Adresse)
            setIce(res.data.entreprises[0].ICE)
            setNomContactPrincipal(res.data.entreprises[0].Nom_Contact_Principal)
            setFonctionContactPrincipal(res.data.entreprises[0].Fonction_Contact_Principal)
            setTelEnt(res.data.entreprises[0].Tel_Ent)
            setTelConPrin(res.data.entreprises[0].Tel_Con_Prin)
            setSecteurActivite(res.data.entreprises[0].Secteur_Activite)
            setTypeNuisible(res.data.entreprises[0].Type_Nuisible)
            setFrequenceIntervention(res.data.entreprises[0].Frequence_intervention)
            setPreferenceContact(res.data.entreprises[0].Preference_Contact)
            setLogin(res.data.login)
            setEmailEnt(res.data.Email)
            setIdEnt(res.data.entreprises[0].id_Entreprise)
        })
    }



    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };

    const handleSubmit = () =>{
        let dataClient
        if(password !== ''){
            dataClient = {
                login : login ,
                password : password,
                Email : emailEnt
            }
        }else{
            dataClient = {
                login : login ,
                Email : emailEnt
            }
        }
        axios.put('https://serv.pestima.yuliagroupe.com/api/clients/'+id,dataClient)
        .then((res)=>{
            if(!res.data.error){
            let dataEntreprise = {
                id_client : res.data.client.id_client,
                Nom : nom ,
                Adresse : adresse ,
                ICE : ice ,
                Nom_Contact_Principal : nomContactPrincipal ,
                Fonction_Contact_Principal : fonctionContactPrincipal ,
                Tel_Ent : telEnt ,
                Tel_Con_Prin : telConPrin ,
                Secteur_Activite: secteurActivite ,
                Type_Nuisible : typeNuisible ,
                Frequence_intervention : frequenceIntervention ,
                Preference_Contact : preferenceContact
            }
            axios.put('https://serv.pestima.yuliagroupe.com/api/entreprises/'+IdEnt,dataEntreprise)
            .then((res)=>{
                if(!res.data.error){
                    setmessage(res.data.message)
                    setOpen(true)
                setTimeout(() => {
                    navigate("/ClientEntrepriseTable")
                }, 3000);
            }else{
                setmessage(res.data.message)
                setOpener(true)
            }
            })
        }else{
            setmessage(res.data.message)
            setOpener(true)
        }
        })
    }

    const handleCloseer = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpener(false);
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };



    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Modifier Client Entreprise</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Nom</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nom}
                                    onChange={(e) => { setNom(e.target.value) }}
                                    id="nom"
                                    name="nom"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Adresse</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={adresse}
                                    onChange={(e) => { setAdresse(e.target.value) }}
                                    id="adresse"
                                    name="adresse"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>ICE</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={ice}
                                    onChange={(e) => { setIce(e.target.value) }}
                                    id="ice"
                                    name="ice"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Nom Contact Principal</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nomContactPrincipal}
                                    onChange={(e) => { setNomContactPrincipal(e.target.value) }}
                                    id="nomContactPrincipal"
                                    name="nomContactPrincipal"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Fonction Contact Principal</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={fonctionContactPrincipal}
                                    onChange={(e) => { setFonctionContactPrincipal(e.target.value) }}
                                    id="fonctionContactPrincipal"
                                    name="fonctionContactPrincipal"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Tel Entreprise</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={telEnt}
                                    onChange={(e) => { setTelEnt(e.target.value) }}
                                    id="telEnt"
                                    name="telEnt"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Tel Contact Principal</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={telConPrin}
                                    onChange={(e) => { setTelConPrin(e.target.value) }}
                                    id="telConPrin"
                                    name="telConPrin"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Email Entreprise</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={emailEnt}
                                    onChange={(e) => { setEmailEnt(e.target.value) }}
                                    id="emailEnt"
                                    name="emailEnt"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Secteur d'Activité</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={secteurActivite}
                                    onChange={(e) => { setSecteurActivite(e.target.value) }}
                                    id="secteurActivite"
                                    name="secteurActivite"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Type de Nuisible</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={typeNuisible}
                                    onChange={(e) => { setTypeNuisible(e.target.value) }}
                                    id="typeNuisible"
                                    name="typeNuisible"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Fréquence Intervention</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={frequenceIntervention}
                                    onChange={(e) => { setFrequenceIntervention(e.target.value) }}
                                    id="frequenceIntervention"
                                    name="frequenceIntervention"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Préférence de Contact</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={preferenceContact}
                                    onChange={(e) => { setPreferenceContact(e.target.value) }}
                                    id="preferenceContact"
                                    name="preferenceContact"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Login</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={login}
                                    onChange={(e) => { setLogin(e.target.value) }}
                                    id="login"
                                    name="login"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Password</Typography>
                                <TextField
                                    type='password'
                                    required
                                    fullWidth
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    id="new_password"
                                    name="new_password"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }} onClick={handleSubmit}>
                                Modifier
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>


        </Box>
    );
}

export default UpdateClientEntreprise;
