import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Box,
  Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Navbar from './Navbar.js';
import fulllogo from './img/full_logo_png.png'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import axios from 'axios';
import { useParams } from 'react-router-dom';
const formatDate = (newdate = new Date()) => {
  const date = new Date(newdate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};


const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: '0',
    padding: '20px',
    position: 'absolute',
    left: '-9999px',
  },
  headerTable: {
    width: '100%',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
    backgroundColor: '#ededed',
  },
  td: {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  },
  appreciate: {
    textAlign: 'center',
  },
  logo: {
    textAlign :'center'
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  info: {
    textAlign: 'right',
  },
  
};

const inputStyles = {
  backgroundColor: '#f2f3f3',
  mb: 2,
  height: '45px',
  borderRadius: '5px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#C0C0C0',
    },
    '&:hover fieldset': {
      borderColor: '#930e0e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#930e0e',
    },
  },
  '& label.Mui-focused': {
    color: '#930e0e',
    fontWeight: 'bold',
  },
};

const ButtonStyles = {
  mb: 2,
  bgcolor: '#930E0E',
  borderRadius: '5px',
  fontFamily: 'Roboto',
  '&:hover': { bgcolor: '#a9281e' },
  color: 'white',
  textTransform: 'none',
  width: '15%',
};

const AuditTable = () => {
  const [data, setData] = useState([]);
  const certificateRef = useRef();
  const {idpassage} = useParams()
  useEffect(()=>{
    axios.get("https://serv.pestima.yuliagroupe.com/api/audits/passage/" + idpassage)
    .then((res)=>{
      const transformedData = res.data.map(audit => {
        return audit.categorie.map(cat => ({
          category: cat.Category,
          date : formatDate(audit.created_at),
          questions: cat.questions.map(q => ({
            id_question:q.ID_Question,
            question: q.Question,
            yes: q.Response === 1, // Assuming Response can be 'yes' or 'no'
            no: q.Response === 0,  // Adjust this logic based on actual response values
            remark: q.Remarque || '',
          }))
        }));
      }).flat();
      setData(transformedData);
    })
  },[])


  const handleIconClick = (sectionIndex, questionIndex, field) => {
    const newData = [...data];
    if (field === 'yes') {
      newData[sectionIndex].questions[questionIndex].yes = !newData[sectionIndex].questions[questionIndex].yes;
      if (newData[sectionIndex].questions[questionIndex].yes) {
        newData[sectionIndex].questions[questionIndex].no = false;
      }
    } else if (field === 'no') {
      newData[sectionIndex].questions[questionIndex].no = !newData[sectionIndex].questions[questionIndex].no;
      if (newData[sectionIndex].questions[questionIndex].no) {
        newData[sectionIndex].questions[questionIndex].yes = false;
      }
    }
    setData(newData);
  };

  const handleRemarkChange = (sectionIndex, questionIndex, value) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].remark = value;
    setData(newData);
  };

  const handleConfirm = () => {
    data.map((c)=>{
        c.questions.map((q)=>{
          axios.put('https://serv.pestima.yuliagroupe.com/api/questions/'+q.id_question,{Response:q.yes ,Remarque:q.remark })
      })
    })
    console.log('Confirmed Data:', data);
  };

  const downloadPdf = () => {
    const input = certificateRef.current;
    html2canvas(input, { scale: 4 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [210, 297], // A4 size in landscape
      });
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
      pdf.save('Check list de contrôle des Locaux Janvier.pdf');
    });
  };


  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2, ml: 2, width: '40%' }}
        >
          Check List de contrôle
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Appréciations</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Remarque</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  <TableRow>
                    <TableCell colSpan={3} style={{ fontWeight: 'bold', borderBottom: '1px solid #E0E0E0' }}>
                      {section.category}
                    </TableCell>
                  </TableRow>
                  {section.questions.map((item, questionIndex) => (
                    <TableRow key={questionIndex}>
                      <TableCell style={{ width: '40%', borderBottom: '1px solid #E0E0E0' }}>{item.question}</TableCell>
                      <TableCell align="center" style={{ borderBottom: '1px solid #E0E0E0' }}>
                        <IconButton
                          color={item.yes ? 'success' : 'default'}
                          onClick={() => handleIconClick(sectionIndex, questionIndex, 'yes')}
                        >
                          <CheckCircleOutlineIcon style={{ color: item.yes ? 'green' : undefined }} />
                        </IconButton>
                        <IconButton
                          color={item.no ? 'error' : 'default'}
                          onClick={() => handleIconClick(sectionIndex, questionIndex, 'no')}
                        >
                          <CancelOutlinedIcon style={{ color: item.no ? 'red' : undefined }} />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #E0E0E0' }}>
                        <TextField
                          value={item.remark}
                          onChange={(e) => handleRemarkChange(sectionIndex, questionIndex, e.target.value)}
                          fullWidth
                          sx={inputStyles}
                          disabled={item.yes}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" m={2} columnGap="50px">
          <Button variant="contained" color="primary" onClick={handleConfirm} sx={ButtonStyles}>
          Enregistrer
          </Button>
          <Button variant="contained" color="primary" onClick={downloadPdf} sx={ButtonStyles}>
          Telecharger Fichier
          </Button>
        </Box>
      </Box>
      <div style={styles.container} ref={certificateRef}>
      <table style={styles.headerTable}>
        <tbody>
          <tr>
            <td style={styles.logo} rowSpan="5"><img src={fulllogo} width='180px' /></td>
            <td style={styles.title} colSpan="2">Processus Management de la Qualité</td>
            <td style={styles.info}>Code: EN.MQ.01</td>
          </tr>
          <tr>
            <td style={styles.title} colSpan="2">Enregistrement</td>
            <td style={styles.info}>Version: 01</td>
          </tr>
          <tr>
            <td style={styles.title} colSpan="2" rowSpan="2">CHECK LISTE DE CONTRÔLE DES LOCAUX</td>
            <td style={styles.info}>{formatDate()}</td>
          </tr>
          <tr>
            <td style={styles.info}>Page 1/1</td>
          </tr>
        </tbody>
      </table>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={{ ...styles.th, background: 'white', border: 'none' }} colSpan="2">DATE : {data[0] ? data[0].date : null}</th>
            <th colSpan="2">Appréciations</th>
            <th>Remarque</th>
          </tr>
          <tr>
            <td colSpan="2"></td>
            <th style={styles.appreciate}>☺</th>
            <th style={styles.appreciate}>⊗</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item,index)=>(
            <>
            <tr>
            <th style={styles.th}>{index+1}.</th>
            <th colSpan="5"><strong>{item.category}</strong></th>
          </tr>
          {item.questions.map((q)=>(
          <tr>
          <td></td>
          <td>{q.question}</td>
          <td style={styles.appreciate}>{q.yes ? 'X' : null}</td>
          <td style={styles.appreciate}>{q.no ? 'X' : null}</td>
          <td>{q.remark}</td>
        </tr>
          ))}
            </>
          ))}
        </tbody>
      </table>
    </div>
    </Box>
  );
};

export default AuditTable;
