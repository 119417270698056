import React, { useEffect, useState } from 'react';
import { Box ,  Card, CardContent, CardHeader, Typography  } from '@mui/material';
import Navbar from './NavbarC.js';
import Footer from './FooterC.js';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line, AreaChart, Area } from 'recharts'
import axios from 'axios';



const insectData = [
    { name: 'Rats', count: 20 },
    { name: 'Flies', count: 150 },
    { name: 'Mosquitoes', count: 300 },
    { name: 'Cockroaches', count: 80 },
    { name: 'Ants', count: 250 },
  ]
  
  const passageData = [
    { name: 'Completed', value: 75 },
    { name: 'Scheduled', value: 25 },
  ]
  

  
  const progressData = [
    { date: '2024-01-15', Measure1: 5, Measure2: 4 }, // Example passage with progress made on two measures
    { date: '2024-02-10', Measure1: 2, Measure2: 1 },
    { date: '2024-03-05', Measure1: 4, Measure2: 2 },
    { date: '2024-04-01', Measure1: 5, Measure2: 3 },
    { date: '2024-05-15', Measure1: 2, Measure2: 2 },
    { date: '2024-06-20', Measure1: 4, Measure2: 3 }, // Full progress for Measure1, close to full for Measure2
  ];
  
  
  const COLORS = ['#930E0E', '#000000']


function StatictiquesC() {

  const [passageData, setPassageData] = useState([
    { name: 'Completed', value: 0 },
    { name: 'Scheduled', value: 0 },
  ]);
  const [insectData, setInsectData] = useState([
    { name: 'Mouches', count: 0 },
    { name: 'Moucherons', count: 0 },
    { name: 'Papillons', count: 0 },
    { name: 'Moustiques', count: 0 },
    { name: 'Abeilles', count: 0 },
  ]);

  const [monthlyInfestationData , setMonthlyInfestationData] = useState([])
  const [progressData, setProgressData] = useState([]);

  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'short' }); // E.g., "Jan", "Feb"
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://serv.pestima.yuliagroupe.com/api/passages/client/'+JSON.parse(localStorage.getItem('UserData')).id_client);
        const passages = response.data;

        // Count based on `ended` value
        const completedCount = passages.filter((item) => parseInt(item.ended) === 1).length;
        const scheduledCount = passages.filter((item) => parseInt(item.ended) === 0).length;

        // Update the passageData
        setPassageData([
          { name: 'Completed', value: completedCount },
          { name: 'Scheduled', value: scheduledCount },
        ]);


        let updatedInsectData = {
          Mouches: 0,
          Moucherons: 0,
          Papillons: 0,
          Moustiques: 0,
          Abeilles: 0,
        };

        // Process each passage to accumulate insect counts
        passages.forEach(passage => {
          passage.pieges.forEach(piege => {
            // Check if piege's insect type matches one of the targeted insects
            console.log(piege)
            if (updatedInsectData.hasOwnProperty('Mouches')) {
              
              updatedInsectData['Mouches'] += piege.Mouches ? parseInt(piege.Mouches) : 0;
            }
            if (updatedInsectData.hasOwnProperty('Moucherons')) {
              updatedInsectData['Moucherons'] += piege.Moucherons ? parseInt(piege.Moucherons) : 0;
            }
            if (updatedInsectData.hasOwnProperty('Papillons')) {
              updatedInsectData['Papillons'] += piege.Papillons ? parseInt(piege.Papillons) : 0;
            }
            if (updatedInsectData.hasOwnProperty('Moustiques')) {
              updatedInsectData['Moustiques'] += piege.Moustiques ? parseInt(piege.Moustiques) : 0 ;
            }
            if (updatedInsectData.hasOwnProperty('Abeilles')) {
              updatedInsectData['Abeilles'] += piege.Abeilles ? parseInt(piege.Abeilles) : 0;
            }
          });
        });

        // Update insectData array with the accumulated counts
        setInsectData([
          { name: 'Mouches', count: updatedInsectData.Mouches },
          { name: 'Moucherons', count: updatedInsectData.Moucherons },
          { name: 'Papillons', count: updatedInsectData.Papillons },
          { name: 'Moustiques', count: updatedInsectData.Moustiques },
          { name: 'Abeilles', count: updatedInsectData.Abeilles },
        ]);



        
        // Initialize monthly insect counts with default values
        let monthlyInsectData = {
          Jan: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Feb: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Mar: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Apr: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          May: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Jun: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Jul: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Aug: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Sep: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Oct: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Nov: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
          Dec: { Mouches: 0, Moucherons: 0, Papillons: 0, Moustiques: 0, Abeilles: 0 },
        };
        
        // Process each passage by month and accumulate insect counts
        passages.forEach((passage) => {
          const month = getMonthName(passage.date_debut); // Assuming `date` is available in `passage`
          
          // Go through each piege in the passage and add its insect counts to the monthly data
          passage.pieges.forEach((piege) => {
            if (piege.Mouches) monthlyInsectData[month].Mouches += parseInt(piege.Mouches);
            if (piege.Moucherons) monthlyInsectData[month].Moucherons += parseInt(piege.Moucherons);
            if (piege.Papillons) monthlyInsectData[month].Papillons += parseInt(piege.Papillons);
            if (piege.Moustiques) monthlyInsectData[month].Moustiques += parseInt(piege.Moustiques);
            if (piege.Abeilles) monthlyInsectData[month].Abeilles += parseInt(piege.Abeilles);
          });
        });
        
        // Format data to fit into `monthlyInfestationData`
        const formattedMonthlyData = Object.entries(monthlyInsectData).map(([month, insects]) => ({
          month,
          Mouches: insects.Mouches,
          Moucherons: insects.Moucherons,
          Papillons: insects.Papillons,
          Moustiques: insects.Moustiques,
          Abeilles: insects.Abeilles,
        }));
        console.log(formattedMonthlyData)
        setMonthlyInfestationData(formattedMonthlyData);



      } catch (error) {
        console.error("Error fetching passage data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchAuditData = async () => {
      try {
        const response = await axios.get('https://serv.pestima.yuliagroupe.com/api/clients/'+JSON.parse(localStorage.getItem('UserData')).id_client+'/audits');

        const transformedData = response.data.map((audit) => {
          let measure1Count = 0;
          let measure2Count = 0;

          audit.categorie.forEach((category) => {
            category.questions.forEach((question) => {
              if ( parseInt(question.Response) === 0) measure1Count += 1;
              if ( parseInt(question.Response) === 1) measure2Count += 1;
            });
          });
          console.log(audit)
          return {
            date: audit.Date,  // Use created_at for the date label
            Measure1: measure1Count,
            Measure2: measure2Count,
          };
        });

        setProgressData(transformedData);  // Set the array of audits as progress data
      } catch (error) {
        console.error("Error fetching audit data", error);
      }
    };

    fetchAuditData();
  }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar activeButton={"Statistiques"}/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
  <Box
    display="grid"
    gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
    gap={4}
    mb={4} // Adds some margin to the bottom
  >
    {/* Card 1: Nombre d'insectes trouvés selon leur type */}
    <Card>
      <CardHeader title="Nombre d'insectes trouvés selon leur type" />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={insectData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#000000" />
            <XAxis dataKey="name" stroke="#000000" />
            <YAxis stroke="#000000" />
            <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #000000' }} />
            <Legend wrapperStyle={{ color: '#000000' }} />
            <Bar dataKey="count" fill="#930E0E" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>

    {/* Card 2: Pourcentage des passages passés par rapport aux passages programmés */}
    <Card>
      <CardHeader title="Passages passés par rapport aux passages programmés" />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={passageData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {passageData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #000000' }} />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  </Box>

  <Box
    display="grid"
    gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
    gap={4}
  >
    {/* Card 3: Taux d'infestation selon chaque mois */}
    <Card>
  <CardHeader title="Taux d'infestation selon chaque mois" />
  <CardContent>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={monthlyInfestationData}>
        <CartesianGrid strokeDasharray="3 3" stroke="#000000" />
        <XAxis dataKey="month" stroke="#000000" />
        <YAxis stroke="#000000" />
        <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #000000' }} />
        <Legend wrapperStyle={{ color: '#000000' }} />
        
        <Line type="monotone" dataKey="Mouches" stroke="#930E0E" activeDot={{ r: 8, fill: '#930E0E' }} />
        <Line type="monotone" dataKey="Moucherons" stroke="#00BFFF" activeDot={{ r: 8, fill: '#00BFFF' }} />
        <Line type="monotone" dataKey="Papillons" stroke="#FFD700" activeDot={{ r: 8, fill: '#FFD700' }} />
        <Line type="monotone" dataKey="Moustiques" stroke="#000000" activeDot={{ r: 8, fill: '#000000' }} />
        <Line type="monotone" dataKey="Abeilles" stroke="#FF69B4" activeDot={{ r: 8, fill: '#FF69B4' }} />
      </LineChart>
    </ResponsiveContainer>
  </CardContent>
</Card>


    {/* Card 4: Taux de consommation des produits */}
    <Card>
  <CardHeader title="Progrès des Mesures Préventives" />
  <CardContent>
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={progressData}>
        <CartesianGrid strokeDasharray="3 3" stroke="#000000" />
        <XAxis dataKey="date" stroke="#000000" />
        <YAxis  stroke="#000000" />
        <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #000000' }} />
        <Legend wrapperStyle={{ color: '#000000' }} />
        
        {/* Measure1 with custom legend name */}
        <Area 
          type="monotone" 
          dataKey="Measure2" 
          name="Mesures Préventives soulevés"
          stackId="1" 
          stroke="#930E0E" 
          fill="#930E0E" 
          fillOpacity={0.5} 
        />
        
        {/* Measure2 with custom legend name */}
        <Area 
          type="monotone" 

          dataKey="Measure1" 
          name="Mesures Préventives"  
          stackId="1" 
          stroke="#000000" 
          fill="#000000" 
          fillOpacity={0.5} 
        />
      </AreaChart>
    </ResponsiveContainer>
  </CardContent>
</Card>


  </Box>
</Box>

            <Footer />
        </Box>
    );
}

export default StatictiquesC;
