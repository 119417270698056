import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



function ClientEntrepriseTable() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data , setData] = useState([])


  const [open, setOpen] = useState(false);
  const [opener, setOpener] = useState(false);
  const [message, setMessage] = useState('');

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [isloged , setisloged] = useState()


  const navigate = useNavigate()


  useEffect(() => {
    setFilteredData(
      data.filter(user =>
        user.Nom.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.Adresse.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.ICE.includes(searchQuery) ||
        user.Tel_Ent.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.Secteur_Activite.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, data]);


  const getEntrepise =() =>{
    axios.get("https://serv.pestima.yuliagroupe.com/api/entreprises")
    .then((res)=>{
      setData(res.data)
      console.log(res.data)
    })
  }

  useEffect(()=>{
    const isLogedCheck = JSON.parse(localStorage.getItem("isloged"))
    setisloged(isLogedCheck)
    
    if(!isLogedCheck){
      navigate("/")
    }else{
      getEntrepise()
    }
  },[])


  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDelete = (id) => {
    axios.delete("https://serv.pestima.yuliagroupe.com/api/clients/" + id)
      .then((res) => {
        if (res.data.error) {
          setOpener(true);
          setMessage(res.data.message);
        } else {
          setOpen(true);
          setMessage(res.data.message);
          getEntrepise();
        }
      });
    handleClose();
  };

  const handleCloseer = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpener(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        {/* <Sidebar /> */}
        <Box component="main" sx={{ flexGrow: 1, p: 1.5, overflowX: 'hidden' }}>
          <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom component="div" sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2 }}>
              Clients Entreprise
            </Typography>
            <TextField
              id="search-client"
              type="search"
              variant="outlined"
              placeholder="Search client..."
              sx={{
                mb: 2,
                fontFamily: 'Arial, sans-serif',
                width: '100%',
                borderRadius: '9px',
                backgroundColor: '#f2f3f3',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '9px',
                  '&:hover fieldset': {
                    borderColor: '#930E0E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#930E0E',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <TableContainer sx={{ width: '100%', mb: 2, borderRadius: '9px', border: '1px solid #C0C0C0' }}>
              <Table sx={{ width: '100%', border: '1px solid #C0C0C0', fontFamily: 'Roboto' }} aria-label="clients table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Nom</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Adresse</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>ICE</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Téléphone Entreprise</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Secteur d'activité</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.Nom}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.Adresse}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.ICE}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.Tel_Ent}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.Secteur_Activite}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, row)}
                        >
                          <ArrowDropDownIcon sx={{ color: 'red' }} />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)&& selectedRow?.id_client === row.id_client}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={()=>navigate("/UpdateClientEntreprise/"+row.id_client)}>Modifier</MenuItem>
                          <MenuItem onClick={()=>handleDelete(row.id_client)}>Supprimer</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button variant="contained" sx={{
              mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily: 'Roboto',
              '&:hover': { bgcolor: '#a9281e' },
              color: 'white',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '15%',
              alignSelf: 'flex-end'
            }} 
            onClick={()=>navigate('/AddClientEntreprise')}
            >
              <AddIcon sx={{ mr: 1 }} />
              Ajouter Nouveau
            </Button>
          </Box>
        </Box>
      </Box>


      <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
        <Alert
          onClose={handleCloseer}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSuccess}>
        <Alert
          onClose={handleCloseSuccess}
          severity='success'
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>



    </Box>
  );
}

export default ClientEntrepriseTable;
