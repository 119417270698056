import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Autocomplete, Chip, Snackbar, Alert, MenuItem, Select, FormControl } from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AddProduct() {
    const [nomProduit, setNomProduit] = useState('');
    const [matieresActives, setMatieresActives] = useState([]);
    const [getmatieresActives, setgetMatieresActives] = useState([]);

    const [Teneur, setTeneur] = useState({});
    const [fournisseurs, setFournisseurs] = useState('');
    const [organismeCible, setOrganismeCible] = useState('');
    const [nAutorisation, setNAutorisation] = useState('');
    const [description, setDescription] = useState('');
    const [minStock, setMinStock] = useState('');
    const [prixUnite, setPrixUnite] = useState('');
    const [prixVentesUnites, setPrixVentesUnites] = useState('');

    const [open ,setOpen] = useState(false)
    const [opener ,setOpener] = useState(false)
    const [message , setmessage ] = useState("")
    const fontFamily = 'Roboto, sans-serif';
    const navigate = useNavigate()
    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
        '& .css-1tw9w9k-MuiAutocomplete-root':{
            position : 'relative',
            top : '-10px'
        },
        '& .css-27alwc-MuiAutocomplete-root .MuiAutocomplete-tag':{
            position:'relative',
            top:'-5px'
        }
    };


useEffect(()=>{
    axios.get("https://serv.pestima.yuliagroupe.com/api/matieresactives")
    .then((res)=>{
        setgetMatieresActives(res.data)
    })
},[])

const handlesubmit = ()=>{
    const data = {
        nom_produits : nomProduit,
        ORGANISME_CIBLE : organismeCible,
        Fournisseur:fournisseurs,
        NAutorisation:nAutorisation,
        Description:description,
        Min_Stock:minStock,
        Prix_Unite:prixUnite,
        Prix_Vent_Unite:prixVentesUnites
    }
    axios.post("https://serv.pestima.yuliagroupe.com/api/produits",data)
    .then((ress)=>{
        if(!ress.data.error){
            matieresActives.map((mat)=>{
                axios.post("https://serv.pestima.yuliagroupe.com/api/produitsmatieractive" , {id_produits:ress.data.produit.id_produits,id_Matieres_actives:mat,TENEUR:Teneur[mat]})
                .then((res)=>{
                    if(res.data.error){
                        setmessage(res.data.message)
                        setOpener(true)
                        axios.delete("https://serv.pestima.yuliagroupe.com/api/produits/" + ress.data.produit.id_produits)
                    }else{
                        setmessage(res.data.message)
                        setOpen(true)
                        setTimeout(() => {
                            navigate("/ProduitTable")
                        }, 3000);
                    }
                })
            })

            
        }else{
            setmessage(ress.data.message)
            setOpener(true)
        }
    })
}

const handleCloseer = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpener(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Ajouter Produit</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Nom de Produit</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nomProduit}
                                    onChange={(e) => { setNomProduit(e.target.value) }}
                                    id="nomProduit"
                                    name="nomProduit"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Matières Actives</Typography>
                                <Autocomplete
    multiple
    options={getmatieresActives ? getmatieresActives
        .filter(ma => !matieresActives.includes(ma.id_Matieres_actives))
        .map((ma) => ({ id_Matieres_actives: ma.id_Matieres_actives, Matieres_actives: ma.Matieres_actives })) : []
    }
    
    getOptionLabel={(option) => option.Matieres_actives}
    value={matieresActives.map(id_Matieres_actives => getmatieresActives.find(ma => ma.id_Matieres_actives === id_Matieres_actives))}
    onChange={(event, newValue) => {
        setMatieresActives(newValue.map(option => option.id_Matieres_actives));
    }}
    renderTags={(value, getTagProps) =>
        value.map((option, index) => (
            <Chip
                key={option.id_Matieres_actives}
                label={option.Matieres_actives}
                {...getTagProps({ index })}
                sx={{height:"24px" , top:"-10.5px"}}
            />
        ))
    }
    renderInput={(params) => (
        <TextField
            {...params}
            variant="outlined"
            sx={inputStyles}
        />
    )}
/>


                            </Box>
                            { matieresActives.map((ma , index)=>(
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Teneur de Matier Actives {index + 1}</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={Teneur[ma]}
                                    onChange={(e) => {
                                        setTeneur((prevTeneur) => ({
                                            ...prevTeneur,
                                            [ma]: e.target.value
                                        }));
                                    }}
                                    id="fournisseurs"
                                    name="fournisseurs"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            ))
                            }
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Fournisseurs</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={fournisseurs}
                                    onChange={(e) => { setFournisseurs(e.target.value) }}
                                    id="fournisseurs"
                                    name="fournisseurs"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Nuisibles Cible:</Typography>
                <FormControl 
                  fullWidth 
                  variant="outlined" 
                  size="small"
                  sx={inputStyles}
                >
                  <Select
                    value={organismeCible}
                    onChange={(e)=>setOrganismeCible(e.target.value)}
                  >
                    <MenuItem value="" disabled><em>Choisir un nuisible</em></MenuItem>
                    <MenuItem value="Dératisation ">Dératisation</MenuItem>
                    <MenuItem value="Désinsectisation">Désinsectisation</MenuItem>
                    <MenuItem value="Déraptilisation">Déraptilisation</MenuItem>
                  </Select>
                </FormControl>
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>N d'Autorisation</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nAutorisation}
                                    onChange={(e) => { setNAutorisation(e.target.value) }}
                                    id="nAutorisation"
                                    name="nAutorisation"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Description</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={description}
                                    onChange={(e) => { setDescription(e.target.value) }}
                                    id="description"
                                    name="description"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Min Stock</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={minStock}
                                    onChange={(e) => { setMinStock(e.target.value) }}
                                    id="minStock"
                                    name="minStock"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Prix Unité</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={prixUnite}
                                    onChange={(e) => { setPrixUnite(e.target.value) }}
                                    id="prixUnite"
                                    name="prixUnite"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Prix Ventes Unités</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={prixVentesUnites}
                                    onChange={(e) => { setPrixVentesUnites(e.target.value) }}
                                    id="prixVentesUnites"
                                    name="prixVentesUnites"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>

                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}
                            onClick={handlesubmit}
                            >
                                Ajouter
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

        </Box>
    );
}

export default AddProduct;
