import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import Navbar from './NavbarC.js';
import Footer from './FooterC.js';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import axios from 'axios';
import divider from './img/divider.png'
import cacher from "./img/cacher.png"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import fulllogo from './img/full_logo_png.png'
import border from './img/border.png'
const passagesData = [
    { date: '19-03-2023', executedBy: 'John Doe' },
    { date: '22-04-2023', executedBy: 'Jane Smith' },
    { date: '15-05-2023', executedBy: 'Alex Brown' },
    { date: '10-06-2023', executedBy: 'Emily Davis' },
    { date: '12-07-2023', executedBy: 'Chris Wilson' }
];

const equipements = [
  { id: 1, name: 'Tue-mouches en bon état', ouiChecked: false, nonChecked: false, action: '' },
  { id: 2, name: 'Fonctionnement des lampes', ouiChecked: false, nonChecked: false, action: '' },
  { id: 3, name: 'Fonctionnement des plaques', ouiChecked: false, nonChecked: false, action: '' },
  // add more equipements as needed
];


function Passages() {
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 4; // Number of rows per page
    const [passagesData , setpassagesData] = useState([])
    const certificateRef = useRef();
    const certificateReffct = useRef();

    const [selectedPassage , setselectedPassage] = useState()
    const [selectedDeratisations, setselectedDeratisation] = useState([]);
    const [selectedDeraptilisation, setselectedDeraptilisation] = useState([]);
    const [selectedDesinsectisations, setselectedDesinsectisations] = useState([]);
    const [isApplyDataComplete, setIsApplyDataComplete] = useState(false);
    const [isApplyDatarapportComplete, setIsApplyDatarapportComplete] = useState(false);
    const [isApplyDataCompletefcp, setIsApplyDataCompletefcp] = useState(false);
  const [isApplyDataCompletefct, setIsApplyDataCompletefct] = useState(false);

    const [Posts , setPosts] = useState([])
    const [postEtats, setPostEtats] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [filterOptions , setfilterOptions]=useState([])


    const [insectocuteurs, setInsectocuteurs] = useState([]);
    const [selectedInsectocuteurId, setSelectedInsectocuteurId] = useState(null);
    const [selectedEquipements, setSelectedEquipements] = useState(equipements);
    const [observation, setObservation] = useState('');
    const [conseils, setConseils] = useState('');


    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    // Calculate the data to show on the current page
    const paginatedData = passagesData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(()=>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/passages/client/"+JSON.parse(localStorage.getItem('UserData')).id_client)
        .then((res)=>{
            setpassagesData(res.data)
            console.log(res.data)
        })
    },[])

    const formatDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };


const ApplyData = (data) => {
  
  // Collect data for all states
  const productsderat = data.produits_a_utilisees
    .filter(produit => produit.produit.ORGANISME_CIBLE === 'Dératisation')
    .map(produit => ({
      id: produit.produit.id_produits,
      nom: produit.produit.nom_produits,
      matiereActive: produit.produit.matieres_actives,
      ORGANISME_CIBLE: produit.ORGANISME_CIBLE
    }));
  
  const productsderap = data.produits_a_utilisees
    .filter(produit => produit.produit.ORGANISME_CIBLE === 'Déraptilisation')
    .map(produit => ({
      id: produit.produit.id_produits,
      nom: produit.produit.nom_produits,
      matiereActive: produit.produit.matieres_actives,
      ORGANISME_CIBLE: produit.ORGANISME_CIBLE
    }));
  
  const productsdesi = data.produits_a_utilisees
    .filter(produit => produit.produit.ORGANISME_CIBLE === 'Désinsectisation')
    .map(produit => ({
      id: produit.produit.id_produits,
      nom: produit.produit.nom_produits,
      matiereActive: produit.produit.matieres_actives,
      ORGANISME_CIBLE: produit.ORGANISME_CIBLE
    }));
  
  // Set all the states
  setselectedPassage(data);
  setselectedDeratisation(productsderat);
  setselectedDeraptilisation(productsderap);
  setselectedDesinsectisations(productsdesi);

  // After all processing is done, mark ApplyData as complete
  setIsApplyDataComplete(true);
};

// UseEffect to trigger downloadPdf when the state updates and ApplyData is done
useEffect(() => {
  // Check if ApplyData has completed and all the required states have been updated
  if (isApplyDataComplete) {
    downloadPdf();  // Now, downloadPdf will be triggered after state updates
    setIsApplyDataComplete(false); // Reset the flag for future invocations
  }
}, [isApplyDataComplete, selectedPassage, selectedDeratisations, selectedDeraptilisation, selectedDesinsectisations]);

    const downloadPdf = () => {
        const input = certificateRef.current;
        html2canvas(input, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [210, 297], // A4 size in landscape
          });
          pdf.addImage(imgData, 'PNG', 0, 0, 297, 210);
          pdf.save('certificate.pdf');
        });
      };



      const ApplyDatafcp = (data) => {
  
        // Collect data for all states
        axios.get("https://serv.pestima.yuliagroupe.com/api/postsbypassage/"+data.id_passage)
        .then((res)=>{
          setPosts(res.data)
          getEtats(res.data,data.id_passage)
        })


      };

  const getEtats = (P,idpassage) => {
    // Fetch all passageEtats for the given passage
    axios.get(`https://serv.pestima.yuliagroupe.com/api/passageetatsby?passage_id=${idpassage}`)
      .then((resPassageEtats) => {
        const passageEtats = resPassageEtats.data.map((pe) => pe.ID_Etat);  // Extract IDs of passageEtats
        // Fetch all etats
        axios.get("https://serv.pestima.yuliagroupe.com/api/etats")
          .then((resEtats) => {
            setfilterOptions(resEtats.data);
  
            // Update selectedFilters based on passageEtats
            const newSelectedFilters = {};
            resEtats.data.forEach((etat) => {
              newSelectedFilters[etat.ID_Etat] = passageEtats.includes(etat.ID_Etat);
            });
            setSelectedFilters(newSelectedFilters);
  
            // Keep the newPostEtat logic as is
            const newPostEtat = {};
            P.forEach((post) => {
              newPostEtat[post.ID_Post] = {};
              resEtats.data.forEach((E) => {
                if (E.ID_Etat === post.ID_Etat) {
                  newPostEtat[post.ID_Post][E.ID_Etat] = true;
                } else {
                  newPostEtat[post.ID_Post][E.ID_Etat] = false;
                }
              });
            });
  
            setPostEtats(newPostEtat);


            setIsApplyDataCompletefcp(true)
          })
          .catch((err) => {
            console.error("Error fetching etats:", err);
          });
      })
      .catch((err) => {
        console.error("Error fetching passageEtats:", err);
      });

      
  };

  useEffect(() => {
    // Check if ApplyData has completed and all the required states have been updated
    if (isApplyDataCompletefcp) {
      downloadPdffcp();  // Now, downloadPdf will be triggered after state updates
      setIsApplyDataCompletefcp(false); // Reset the flag for future invocations
    }
  }, [isApplyDataCompletefcp, selectedFilters, filterOptions, postEtats]);


      const EtatNumber = (Etat) => {
        let Counter = 0 ;
        Posts.map((p)=>{
          if(postEtats[p.ID_Post][Etat]){
            Counter++
          }
        })
        return Counter
      };

      const downloadPdffcp = () => {


        // Create an iframe element
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '1500px';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        iframe.style.opacity = '0'; // Make it invisible
        document.body.appendChild(iframe);
    
        // Write content to the iframe
        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write('<!DOCTYPE html><html><head><title>PDF Content</title>');
        doc.write('</head><body>');
        doc.write(`
          <div class="container">
            <table class="header-table">
              <tbody>
                <tr>
                  <td class="logo" rowSpan="5"><img src="${fulllogo}" width="180px" /></td>
                  <td class="title" colSpan="2" style="text-align: center;">Processus Management de la Qualité</td>
                  <td class="info">Code: EN.MQ.01</td>
                </tr>
                <tr>
                  <td class="title" colSpan="2" style="text-align: center;">Enregistrement</td>
                  <td class="info">Version: 01</td>
                </tr>
                <tr>
                  <td class="title" colSpan="2" rowSpan="2" style="text-align: center;">FICHE DE CONTRÔLE DES POSTES D’APPATAGES</td>
                  <td class="info">Date: ${formatDate()}</td>
                </tr>
                <tr>
                  <td class="info">Page 1/1</td>
                </tr>
              </tbody>
            </table>
        
            <section class="header-info">
              <div>
                <p>Client :</p>
                <p>Nombre de postes mis en place : ${Posts.length}</p>
                <p>Produits utilisés :</p>
                <p style="margin-left: 70px;">- Boite externe : ${Posts.filter(p => p.INTERNE === 0).length}</p>
                <p style="margin-left: 70px;">- Boite interne : ${Posts.filter(p => p.INTERNE === 1).length}</p>
              </div>
              <div>
                <p>Date : ${formatDate(Posts[0] ? Posts[0].passage.date_passage : null)}</p>
                <p>Responsable :</p>
              </div>
            </section>
        
            <h4>ETAT DES POSTES</h4>
            <section class="etat-des-postes">
              <table>
                <thead>
                  <tr>
                    ${filterOptions.map(option => 
                      selectedFilters[option.ID_Etat] ? 
                      `<th key="${option.Etat}">(${option.Etat}) ${option.description}</th>` : ''
                    ).join('')}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    ${filterOptions.map(option => 
                      selectedFilters[option.ID_Etat] ? 
                      `<td key="${option.Etat}">${EtatNumber(option.ID_Etat)}</td>` : ''
                    ).join('')}
                  </tr>
                </tbody>
              </table>
            </section>
        
            <h4>POSTES – PIEGES – PRODUIT</h4>
            <section class="postes-pieges-produit">
              <table>
                <thead>
                  <tr>
                    <th rowSpan="2">INTERNE</th>
                    <th colSpan="${filterOptions.filter(option => option.intern === 1 && selectedFilters[option.ID_Etat] === true).length + 2}">OBSERVATIONS</th>
                    <th rowSpan="2">Externe</th>
                    <th colSpan="${filterOptions.filter(option => option.intern === 0 && selectedFilters[option.ID_Etat] === true).length + 2}">OBSERVATIONS</th>
                  </tr>
                  <tr>
                    <th>N°</th>
                    ${filterOptions.map(option => 
                      selectedFilters[option.ID_Etat] && option.intern === 1 ? 
                      `<th key="${option.Etat}">${option.Etat}</th>` : ''
                    ).join('')}
                    <th>OBSERVATIONS</th>
                    <th>N°</th>
                    ${filterOptions.map(option => 
                      selectedFilters[option.ID_Etat] && option.intern === 0 ? 
                      `<th key="${option.Etat}">${option.Etat}</th>` : ''
                    ).join('')}
                    <th>OBSERVATIONS</th>
                  </tr>
                </thead>
                <tbody>
                  ${Posts.map((p, index) => `
                    <tr key="${p.ID_Post}">
                      <td>${index + 1}</td>
                      <td>${p.ID_Post}</td>
                      ${filterOptions.map(option => 
                        selectedFilters[option.ID_Etat] && option.intern === 1 ? 
                        `<td key="${option.Etat}">${postEtats[p.ID_Post][option.ID_Etat] && p.INTERNE ? 'X' : ''}</td>` : ''
                      ).join('')}
                      <td>${p.INTERNE && p.OBSERVATIONS !== null ? p.OBSERVATIONS : ''}</td>
                      <td>${index + 1}</td>
                      <td>${p.ID_Post}</td>
                      ${filterOptions.map(option => 
                        selectedFilters[option.ID_Etat] && option.intern === 0 ? 
                        `<td key="${option.Etat}">${postEtats[p.ID_Post][option.ID_Etat] && !p.INTERNE ? 'X' : ''}</td>` : ''
                      ).join('')}
                      <td>${!p.INTERNE && p.OBSERVATIONS !== null ? p.OBSERVATIONS : ''}</td>
                    </tr>
                  `).join('')}
                  
                </tbody>
              </table>
            </section>
          </div>
        `);
        
        doc.write('</body></html>');
        doc.close();
    
        // Wait for the iframe content to be fully loaded
        iframe.onload = () => {
            const pdf = new jsPDF('portrait', 'pt', 'a4');
            pdf.html(iframe.contentWindow.document.body, {
                callback: (doc) => {
                    doc.save('fcp.pdf');
                    document.body.removeChild(iframe); // Remove the iframe after generating PDF
                },
                x: 10,
                y: 10,
                html2canvas: { scale: 0.39 },
                width: 190 // A4 width in mm (210mm) minus margins
            });
        };
    };
    

    const getAllPiege = (idpassage) => {

      axios.get("https://serv.pestima.yuliagroupe.com/api/piegesbypassage/"+idpassage)
        .then((res) => {
          console.log(res.data)
          const data = res.data.filter((piege) => piege.ID_Piege !== 0)
          setInsectocuteurs(data);
          setSelectedInsectocuteurId(data[0]?.ID_Piege);


          axios.get(`https://serv.pestima.yuliagroupe.com/api/fctdata/${idpassage}`)
          .then((res) => {
            const data = res.data;
        
            // Check if data has rows before updating
            const updatedEquipements = data.length > 0 ? equipements.map(equipement => {
              if (equipement.id === 1) {
                return { ...equipement, ouiChecked: data[0].EtatTumoche === 1, nonChecked: data[0].EtatTumoche === 0, action: data[0].EtatTumocheAction };
              } else if (equipement.id === 2) {
                return { ...equipement, ouiChecked: data[0].lampes === 1, nonChecked: data[0].lampes === 0, action: data[0].lampesAction };
              } else if (equipement.id === 3) {
                return { ...equipement, ouiChecked: data[0].plaques === 1, nonChecked: data[0].plaques === 0, action: data[0].plaquesAction };
              }
              return equipement;
            }) : equipements; // Keep initial state if no data
        
            setSelectedEquipements(updatedEquipements);
            setObservation(data.length > 0 ? data[0].Observation : '');
            setConseils(data.length > 0 ? data[0].Conseils : '');
            setIsApplyDataCompletefct(true)
          });
        
        });





        
    };


    useEffect(() => {
      // Check if ApplyData has completed and all the required states have been updated
      if (isApplyDataCompletefct) {
        downloadPdffct();  // Now, downloadPdf will be triggered after state updates
        setIsApplyDataComplete(false); // Reset the flag for future invocations
      }
    }, [isApplyDataCompletefct, selectedEquipements]);


  const downloadPdffct = () => {
    const input = certificateReffct.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [210, 297], // A4 size in landscape
      });
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
      pdf.save('Fiche de contrôle des insectocuteurs.pdf');
    });
  };



  const ApplyDatarapport = (data) => {
  
    // Collect data for all states
    const productsderat = data.produits_a_utilisees
      .filter(produit => produit.produit.ORGANISME_CIBLE === 'Dératisation')
      .map(produit => ({
        id: produit.produit.id_produits,
        nom: produit.produit.nom_produits,
        matiereActive: produit.produit.matieres_actives,
        ORGANISME_CIBLE: produit.ORGANISME_CIBLE
      }));
    
    const productsderap = data.produits_a_utilisees
      .filter(produit => produit.produit.ORGANISME_CIBLE === 'Déraptilisation')
      .map(produit => ({
        id: produit.produit.id_produits,
        nom: produit.produit.nom_produits,
        matiereActive: produit.produit.matieres_actives,
        ORGANISME_CIBLE: produit.ORGANISME_CIBLE
      }));
    
    const productsdesi = data.produits_a_utilisees
      .filter(produit => produit.produit.ORGANISME_CIBLE === 'Désinsectisation')
      .map(produit => ({
        id: produit.produit.id_produits,
        nom: produit.produit.nom_produits,
        matiereActive: produit.produit.matieres_actives,
        ORGANISME_CIBLE: produit.ORGANISME_CIBLE
      }));
    
    // Set all the states
    setselectedPassage(data);
    setselectedDeratisation(productsderat);
    setselectedDeraptilisation(productsderap);
    setselectedDesinsectisations(productsdesi);
  
    // After all processing is done, mark ApplyData as complete
    setIsApplyDatarapportComplete(true);
  };
  
  // UseEffect to trigger downloadPdf when the state updates and ApplyData is done
  useEffect(() => {
    // Check if ApplyData has completed and all the required states have been updated
    if (isApplyDatarapportComplete) {
      downloadPdfraport();  // Now, downloadPdf will be triggered after state updates
      setIsApplyDatarapportComplete(false); // Reset the flag for future invocations
    }
  }, [isApplyDatarapportComplete, selectedPassage, selectedDeratisations, selectedDeraptilisation, selectedDesinsectisations]);

  const getMonthYearInFrench = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' })
      .replace(/^./, match => match.toUpperCase()); // Capitalizes the first letter
  };

  const downloadPdfraport = () => {
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '1500px';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    iframe.style.opacity = '0';
    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(`
     <!DOCTYPE html>
        <html>
        <head>
            <title>PDF Content</title>
            <style>
                @page {
                    size: A4;
                    margin: 0;
                }
                body {
                    margin: 0;
                    padding: 0;
                    font-family: Arial, sans-serif;
                }
                .page {
                    width: 210mm;
                    height: 297mm;
                    padding: 20px;
                    box-sizing: border-box;
                    position: relative;
                    page-break-after: always;
                }
                .star-border {
                    position: relative;
                    height: calc(100% - 40px);
                    padding: 20px;
                }
                .star-border::before,
                .star-border::after {
                    content: "✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱ ✱";
                    color: red;
                    position: absolute;
                    left: 0;
                    right: 0;
                    text-align: center;
                }
                .star-border::before { top: 0; }
                .star-border::after { bottom: 0; }
                .star-border-left,
                .star-border-right {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 20px;
                }
                .star-border-left {
                    left: 0;
                    border-left: 0px dashed red;
                }
                .star-border-right {
                    right: 0;
                    border-right: 0px dashed red;
                }
                .star-border-left::before,
                .star-border-right::before {
                    content: "✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱\\A✱";
                    white-space: pre;
                    color: red;
                    position: absolute;
                    top: 2.5px;
                    bottom: 20px;
                    line-height: 1.5em;
                }
                .star-border-left::before { left: -10px; }
                .star-border-right::before { right: -10px; }
                .header {
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 100px;
                }
                .logo {
                    width: 300px;
                    margin-bottom: 100px;
                }
                .content-table {
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 50px;
                    font-size: 24px
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                .content-table td {
                    padding: 10px;
                    vertical-align: top;
                }
                .content-table td:first-child {
                    font-weight: bold;
                    width: 200px;
                }
                .red-text {
                    color: red;
                }
                .data-table th, .data-table td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: left;
                }
                .checkbox-list {
                    list-style-type: none;
                    padding-left: 20px;
                }
                .checkbox-list li {
                    margin-bottom: 30px; /* Adjust spacing as needed */
                }
                .checkbox-list li::before {
                    content: "□";
                    margin-right: 5px;
                }
            </style>
        </head>
        <body>
            <!-- First Page -->
            <div class="page">
                <div class="star-border">
                    <div class="star-border-left"></div>
                    <div class="star-border-right"></div>
                    <div class="header">
                        <img src="${fulllogo}" class="logo" alt="PESTIMA Logo" />
                        <h1 style="font-size: 24px; margin: 30px 0;">RAPPORT DE DÉRATISATION, DÉSINSECTISATION & DÉREPTILISATION</h1>
                    </div>
                    <table class="content-table">
                        <tr>
                            <td>Période</td>
                            <td class="red-text">${getMonthYearInFrench(selectedPassage.date_debut)}</td>
                        </tr>
                        <tr>
                            <td>Champs d'intervention</td>
                            <td class="red-text">L'ensemble des Locaux site de ${selectedPassage ? selectedPassage.convention.client.entreprises[0] ? selectedPassage.convention.client.entreprises[0].Nom : selectedPassage.convention.client.personnes[0].Nom : null}</td>
                        </tr>
                        <tr>
                            <td>Liste des intervenants</td>
                            <td class="red-text">
                                Mlle Asmaa FATHI<br/>
                                Mr Khalid Tabif
                            </td>
                        </tr>
                        <tr>
                            <td>Destinataire</td>
                            <td class="red-text">${selectedPassage ? selectedPassage.convention.client.entreprises[0] ? selectedPassage.convention.client.entreprises[0].Nom : selectedPassage.convention.client.personnes[0].Nom : null}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <!-- Second Page -->
            <div class="page">
                <div style="padding: 20px;">
<table class="header-table" style="width: 100%; border-collapse: collapse; border: 1px solid black;">
  <tbody>
    <tr>
      <td class="logotd" rowSpan="5" style="border: 1px solid black;">
        <img src="${fulllogo}" width="180px" />
      </td>
      <td class="title" colSpan="2" style="text-align: center; border: 1px solid black;">Processus Management de la Qualité</td>
      <td class="info" style="border: 1px solid black;">Code: EN.MQ.01</td>
    </tr>
    <tr>
      <td class="title" colSpan="2" style="text-align: center; border: 1px solid black;">Enregistrement</td>
      <td class="info" style="border: 1px solid black;">Version: 01</td>
    </tr>
    <tr>
      <td class="title" colSpan="2" rowSpan="2" style="text-align: center; border: 1px solid black;">RAPPORT D'INTERVENTION 3D</td>
      <td class="info" style="border: 1px solid black;">Date: ${formatDate()}</td>
    </tr>
    <tr>
      <td class="info" style="border: 1px solid black;">Page 2/4</td>
    </tr>
  </tbody>
</table>

                    
                    <div style="margin-top: 50px;">
                        <h2>I- Introduction</h2>
                        <p>Les travaux ont été exécutés par notre technicien : <span class="red-text">Mr Khalid Tabif</span> sous la supervision de <span class="red-text">Mme Asmaa FATHI</span></p>
                        <p>L'intervention avait pour but de :</p>
                        <ul>
                            <li>Détecter la présence des nuisibles, principalement rongeurs.</li>
                            <li>Lister les points d'amélioration des standards de sinisation propres à limiter la pénétration, la propagation et la prolifération des nuisibles à l'intérieur de vos locaux.</li>
                        </ul>
                        <p>Les observations contenues dans ce bilan effectué le <span class="red-text">${selectedPassage.date_debut}</span>.</p>

                        <h2>II- Secteurs visités</h2>
                        <div style="columns: 2;">
                            <ul class="checkbox-list">
                                <li>Vestiaire</li>
                                <li>Zone de réception</li>
                                <li>Zone de palettisation</li>
                                <li>Zone de déchets</li>
                                <li>Magasin de stockage des MP</li>
                                <li>Toilettes</li>
                                <li>Salle de conditionnement</li>
                                <li>Vestiaires</li>
                                <li>Magasin de stockage des emballages vides</li>
                                <li>L'entourage du Bâtiment</li>
                            </ul>
                        </div>

                        <div style="margin-top: 20px;">
                            <h3>Recommandations suite à la visite des locaux</h3>
                            <p class="red-text">Suite au contrôle des locaux du site de conditionnement AGRICOLE CENTER, nous n'avons constaté aucun point qui peut favoriser l'accès des nuisibles. (cas 1)</p>
                            <p class="red-text">Nous avons constaté les points suivants (cas 2) :</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Third Page -->
            <div class="page">
<table class="header-table" style="width: 100%; border-collapse: collapse; border: 1px solid black;">
  <tbody>
    <tr>
      <td class="logotd" rowSpan="5" style="border: 1px solid black;">
        <img src="${fulllogo}" width="180px" />
      </td>
      <td class="title" colSpan="2" style="text-align: center; border: 1px solid black;">Processus Management de la Qualité</td>
      <td class="info" style="border: 1px solid black;">Code: EN.MQ.01</td>
    </tr>
    <tr>
      <td class="title" colSpan="2" style="text-align: center; border: 1px solid black;">Enregistrement</td>
      <td class="info" style="border: 1px solid black;">Version: 01</td>
    </tr>
    <tr>
      <td class="title" colSpan="2" rowSpan="2" style="text-align: center; border: 1px solid black;">RAPPORT D'INTERVENTION 3D</td>
      <td class="info" style="border: 1px solid black;">Date: ${formatDate()}</td>
    </tr>
    <tr>
      <td class="info" style="border: 1px solid black;">Page 3/4</td>
    </tr>
  </tbody>
</table>

                    
                    <div style="margin-top: 20px;">
                        <h2>III- Traitements effectués</h2>
                        <h3>1. Dératisation:</h3>
                        <p>Lors de ce traitement nous avons :</p>
                        <ul>
                            <li class="red-text">Changer les colles et les raticides.</li>
                        </ul>

                        <p style="margin-top: 20px;"><strong>➤ Appréciation générale :</strong></p>
                        <p>Une activité des rongeurs a été détectée dans l'entourage des bâtiments</p>
                        <ul>
                            <li><strong>Postes chimiques :</strong> L'appât qui contient des produits raticides.</li>
                            <li><strong>Postes écologiques :</strong> L'appât qui contient de la colle écologique</li>
                        </ul>

                        <div style="margin-top: 20px;">
                            <table style="width: 100%; border-collapse: collapse; font-family: Arial, sans-serif;">
  <tr>
    <th colspan="4" style="border: 1px solid black;background-color: #f8d7da; font-weight: bold; font-size: 16px; text-align: center;">
      ETAT DES POSTES
    </th>
  </tr>
<tr>
  <td style="border: 1px solid black; padding: 8px; text-align: center;">
    Taux d’infestations :
  </td>
  <td style="border: 1px solid black; padding: 8px; text-align: center; color: red; font-weight: bold;">
    00 % <br>(Consommé/total)
  </td>
  <td style="border: 1px solid black; padding: 8px; text-align: center;">
    Fréquence de contrôle :
  </td>
  <td style="border: 1px solid black; padding: 8px; text-align: center; color: red; font-weight: bold;">
    1 mois <br>(validité)
  </td>
</tr>
  <tr>
    <th colspan="2" style="border: 1px solid black; padding: 8px; text-align: center; background-color: #f8d7da; font-weight: bold;">
      Taux d’infestations (TI)
    </th>
    <th colspan="2" style="border: 1px solid black; padding: 8px; text-align: center; background-color: #f8d7da; font-weight: bold;">
      Fréquence de contrôle
    </th>
  </tr>
  <tr>
    <td colspan="2" style="border: 1px solid black; padding: 8px; text-align: center;">TI &gt; 50%</td>
    <td colspan="2" style="border: 1px solid black; padding: 8px; text-align: center;">7 jours</td>
  </tr>
  <tr>
    <td colspan="2" style="border: 1px solid black; padding: 8px; text-align: center;">35% &lt; TI &lt; 50%</td>
    <td colspan="2" style="border: 1px solid black; padding: 8px; text-align: center;">15 jours</td>
  </tr>
  <tr>
    <td colspan="2" style="border: 1px solid black; padding: 8px; text-align: center;">25% &lt; TI &lt; 35%</td>
    <td colspan="2" style="border: 1px solid black; padding: 8px; text-align: center;">21 jours</td>
  </tr>
  <tr>
    <td colspan="2" style="border: 1px solid black; padding: 8px; text-align: center;">TI &lt; 25%</td>
    <td colspan="2" style="border: 1px solid black; padding: 8px; text-align: center;">1 mois</td>
  </tr>
</table>
                        </div>

                        <p style="margin-top: 20px;"><strong>➤ Taux d'infestation :</strong></p>
                        <p>D'après le contrôle de l'état des lieux, nous avons obtenu un taux d'infestation de <span class="red-text">00 %</span> et par conséquence la <span class="red-text">fréquence du traitement de dératisation est mensuelle.</span></p>
                    </div>
                </div>
            </div>

            <!-- Fourth Page -->
            <div class="page">
<table class="header-table" style="width: 100%; border-collapse: collapse; border: 1px solid black;">
  <tbody>
    <tr>
      <td class="logotd" rowSpan="5" style="border: 1px solid black;">
        <img src="${fulllogo}" width="180px" />
      </td>
      <td class="title" colSpan="2" style="text-align: center; border: 1px solid black;">Processus Management de la Qualité</td>
      <td class="info" style="border: 1px solid black;">Code: EN.MQ.01</td>
    </tr>
    <tr>
      <td class="title" colSpan="2" style="text-align: center; border: 1px solid black;">Enregistrement</td>
      <td class="info" style="border: 1px solid black;">Version: 01</td>
    </tr>
    <tr>
      <td class="title" colSpan="2" rowSpan="2" style="text-align: center; border: 1px solid black;">RAPPORT D'INTERVENTION 3D</td>
      <td class="info" style="border: 1px solid black;">Date: ${formatDate()}</td>
    </tr>
    <tr>
      <td class="info" style="border: 1px solid black;">Page 4/4</td>
    </tr>
  </tbody>
</table>

                    
                    <div style="margin-top: 20px;">
                        <h3>2. Désinsectisation :</h3>
                        <p>Lors de ce traitement nous avons :</p>
                        <ul>
                            <li>✓ Traité par pulvérisation de l'insecticide « ADVION WDG », au niveau de la zone externe du local.</li>
                            <li>✓ Traité par gel « ADVION WDG » l'ensemble des vestiaires et toilettes.</li>
                            <li>✓ Traité par fumigation « GOLIATH » les canalisations.</li>
                        </ul>

                        <h3>3. Déreptilisation</h3>
                        <p>Lors de ce traitement nous avons traité par produit répulsifs, contre les serpents et les scorpions. les alentours de votre société.</p>

                        <h2>IV- Récapitulatif des produits utilisés</h2>
                        <table class="data-table">
                            <tr>
                                <th style="background-color: #f8d7da;">Nom commercial</th>
                                <th style="background-color: #f8d7da;">Fabricant</th>
                                <th style="background-color: #f8d7da;">Composition chimique</th>
                                <th style="background-color: #f8d7da;">Quantité utilisée</th>
                                <th style="background-color: #f8d7da;">Concentration</th>
                                <th style="background-color: #f8d7da;">Lieux d'intervention</th>
                            </tr>
                            <tr>
                                <td colspan="6" style="background-color: #f0f0f0;"><strong>DÉRATISATION</strong></td>
                            </tr>
                            <tr>
                                <td>MASTERTRAP Source</td>
                                <td>PRIMA - Italie</td>
                                <td>-</td>
                                <td>20 pièces</td>
                                <td>-</td>
                                <td class="red-text">Points d'appâtage Intérieur</td>
                            </tr>
                            <tr>
                                <td>MASTERROD TRACK</td>
                                <td>PRIMA - Italie</td>
                                <td>Brodifacoum</td>
                                <td>40 Blocs</td>
                                <td>0,005 %</td>
                                <td class="red-text">Points d'appâtage Externe</td>
                            </tr>
                            <tr>
                                <td colspan="6" style="background-color: #f0f0f0;"><strong>DÉSINSECTISATION (Pulvérisation)</strong></td>
                            </tr>
                            <tr>
                                <td>ADVION WDG</td>
                                <td>PRIMA - Italie</td>
                                <td>Indoxacarbe</td>
                                <td>3 sachets</td>
                                <td>20%</td>
                                <td class="red-text">Vestiares, toilettes et zone externe</td>
                                </tr>
                                <tr>
                                <td colspan="6" style="background-color: #f0f0f0;"><strong>DEREPTILISATION (Poudre)</strong></td>
                            </tr>
                            <tr>
                                <td>REPULSIF STOPPER</td>
                                <td>ORMA – Italie</td>
                                <td>UNDECAN-2-one</td>
                                <td>3 kg</td>
                                <td>0,05%</td>
                                <td class="red-text">Zone Externe</td>
                                </tr>
                                </table>
                                </div>
                                </div>
                                </div>
                                </body>
                                </html>
    `);
    doc.close();

    iframe.onload = () => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        
        html2canvas(iframe.contentWindow.document.querySelector('.page:nth-child(1)'), {
            scale: 1
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
            
            return html2canvas(iframe.contentWindow.document.querySelector('.page:nth-child(2)'), {
                scale: 1
            });
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
            
            return html2canvas(iframe.contentWindow.document.querySelector('.page:nth-child(3)'), {
                scale: 1
            });
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
          
          return html2canvas(iframe.contentWindow.document.querySelector('.page:nth-child(4)'), {
              scale: 1
          });
      })
        .then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
            
            pdf.save('Rapport.pdf');
            document.body.removeChild(iframe);
        });
    };
};


      const today = new Date();
      const formattedDate = formatDate(today);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar activeButton={"Passages"}/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ mt: 2 }}>
                    <TableContainer style={{ borderRadius: '9px', border: '1px solid #C0C0C0', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Date du passage</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Executé par</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">FCP</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">FCT</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Certificate</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Rapport de passage</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((passage, index) => (
                                    <TableRow 
                                        key={index} 
                                        sx={{ borderBottom: '1px solid #C0C0C0' }} // Add border to each row
                                    >
                                        <TableCell sx={{border:"0px"}}>{passage.date_debut}</TableCell>
                                        <TableCell sx={{border:"0px"}}>{passage.user.Nom+" "+passage.user.Prenom}</TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                                onClick={()=>{ApplyDatafcp(passage)}}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                                onClick={()=>{getAllPiege(passage.id_passage)}}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                                onClick={()=>ApplyData(passage)}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                                onClick={()=>ApplyDatarapport(passage)}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination controls */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                        <IconButton 
                            onClick={handlePreviousPage} 
                            disabled={currentPage === 1} 
                            sx={{ color: '#930e0e' }} // Red color for arrow
                        >
                            <PlayArrowIcon sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                        <Typography sx={{ mx: 2 }}>{currentPage}</Typography>
                        <IconButton 
                            onClick={handleNextPage} 
                            disabled={currentPage * rowsPerPage >= passagesData.length} 
                            sx={{ color: '#930e0e' }} // Red color for arrow
                        >
                            <PlayArrowIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Footer />


                    {/* pdf */}
        <div>
<div
  ref={certificateRef}
  id='containerpdf'
  style={{
    padding: '20px',
    width: '297mm',
    height: '210mm',
    border: '1px solid #ddd',
    marginBottom: '20px',
    boxSizing: 'border-box',
    backgroundImage: 'url(./bg.png)',
    backgroundSize: 'contain',
    position: 'absolute',
    left: '-9999px',
  }}
>
  <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '25px' }}>
    <div style={{ marginLeft: '50px', display: 'flex' }}>
      <img src='/logo.png' alt='Logo' style={{ height: '60px' }} />
    </div>
    <h1 style={{fontFamily:"Yeseva One" , fontSize:42 , marginBottom:"0px"}}>ATTESTATION</h1>
    <p style={{fontFamily:"Open Sans"}}>
      Nous soussignés, société <b>PESTIMA</b> certifions que<br />
      Le traitement de dératisation, désinsectisation et déreptilisation a été effectué dans l'ensemble des locaux de :
    </p>
    <h2 style={{fontFamily:"Yeseva One" , color:"#C00000" , fontSize:26 , margin:"5px 0px"}}>{selectedPassage ? selectedPassage.convention.client.entreprises[0] ? selectedPassage.convention.client.entreprises[0].Nom : selectedPassage.convention.client.personnes[0].Nom : null}</h2>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ display: 'flex', justifyContent: 'space-around', width: '90%' ,columnGap:"200px" }}>
      <div style={{ flex: '1', textAlign: 'center' }}>
        <h3 style={{marginRight:"70px" , marginBottom:"0px", marginTop:"10px",fontFamily:"Open Sans"}}>DERATISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' }}>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454" , fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedPassage ? selectedDeratisations.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
          
          
        </div>
      </div>
      <div style={{ flex: '1', textAlign: 'center' }}>
        <h3 style={{marginRight:"50px" , marginBottom:"0px",marginTop:"10px" ,fontFamily:"Open Sans"}}>DESINSECTISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' }}>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedPassage ? selectedDesinsectisations.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
        </div>
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
      <div style={{ textAlign: 'center' }}>
        <h3 style={{marginRight:"20px" , marginBottom:"0px" , fontFamily:"Open Sans" , marginTop:2}}>DERAPTILISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' , columnGap:"80px" }}>
          <p style={{margin:"0px" , marginBottom:"2px" , fontFamily:"Carlito", color:"#545454", fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedPassage ? selectedDeraptilisation.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
        </div>
      </div>
    </div>
  </div>
  <div style={{ textAlign: 'center', marginTop: '10px' }}>
    <p style={{margin:"0px" , fontWeight:"700"}}>Ce certificat est délivré pour servir et valoir ce que de droit.</p>
    <img src={divider} width="60px"/>
  </div>
  <div style={{display:"flex" , flexDirection:"column" , alignItems:"center" , position:"absolute" , right:"100px" , top:"600px"}}>
  <p style={{margin: "0px",fontFamily:"Open Sans" , fontSize:14}}><b style={{color:"#C00000"}}>FAIT À :</b> {`CASABLANCA`} <b style={{color:"#C00000"}}>LE : </b>{formattedDate}</p>
  <p style={{margin:"0px",fontFamily:"Open Sans" , color:"#808080",fontSize:14 , fontWeight:700}}>DIRECTION </p>
  <img src={cacher} style={{marginTop:"5px"}}/>
  </div>
</div>


    </div>


    <div className="container"
          ref={certificateReffct}
          style={{
            position: 'absolute',
            left: '-9999px',
          }}
          id='containerpdf'>
        <table className="header-table">
          <tbody>
            <tr>
              <td className="logo" rowSpan="5" ><img src={fulllogo} width='180px' /></td>
              <td className="title" colSpan="2">Processus Management de la Qualité</td>
              <td className="info">Code: EN.MQ.01</td>
            </tr>
            <tr>
              <td className="title" colSpan="2">Enregistrement</td>
              <td className="info">Version: 01</td>
            </tr>
            <tr>
              <td className="title" colSpan="2" rowSpan="2">Fiche de contrôle des insectocuteurs</td>
              <td className="info">{formatDate()}</td>
            </tr>
            <tr>
              <td className="info">Page 1/1</td>
            </tr>
          </tbody>
        </table>

      <table className="info-table">
        <tbody>
          <tr>
            <td width="20%">Raison social du client:</td>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td>Lieu d'intervention :</td>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td>Intervenant :</td>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>

      <table className="equipment-table">
        <tbody>
          <tr>
            <td className="section-title" colSpan="4">État des Équipements :</td>
          </tr>
          <tr>
            <td>Tue-mouches en bon état :</td>
            <td>
              <div className="checkbox-group">
                <label><input type="checkbox" checked={selectedEquipements[0].ouiChecked}/> Oui</label>
                <label><input type="checkbox" checked={selectedEquipements[0].nonChecked}/> Non</label>
              </div>
            </td>
            <td colSpan="2">Action à prévoir: {selectedEquipements[0].action}</td>
          </tr>
          <tr>
            <td>Fonctionnement des lampes :</td>
            <td>
              <div className="checkbox-group">
                <label><input type="checkbox" checked={selectedEquipements[1].ouiChecked}/> Oui</label>
                <label><input type="checkbox" checked={selectedEquipements[1].nonChecked}/> Non</label>
              </div>
            </td>
            <td colSpan="2">Action à prévoir: {selectedEquipements[1].action}</td>
          </tr>
          <tr>
            <td>Fonctionnement des plaques :</td>
            <td>
              <div className="checkbox-group">
                <label><input type="checkbox" checked={selectedEquipements[2].ouiChecked}/> Oui</label>
                <label><input type="checkbox" checked={selectedEquipements[2].nonChecked}/> Non</label>
              </div>
            </td>
            <td colSpan="2">Action à prévoir: {selectedEquipements[2].action}</td>
          </tr>
          <tr>
            <td>Détails supplémentaires :</td>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>

      <table className="sticky-plates-table">
        <tbody>
          <tr>
            <td className="section-title" colSpan="8">État des plaques collantes :</td>
          </tr>
          <tr>
            <th>Numéro de l'insectocuteurs</th>
            <th>Emplacement</th>
            <th>Moucherons</th>
            <th>Mouches</th>
            <th>Papillons</th>
            <th>Abeilles</th>
            <th>Moustiques</th>
            <th>ACTION</th>
          </tr>
          {insectocuteurs.map((i)=>(
            <tr>
            <td>Insectocuteurs {i.ID_Piege}</td>
            <td>{i.Emplacement}</td>
            <td>{i.Watt == 20 ? parseInt(i.Moucherons) * 190 : parseInt(i.Moucherons) * 300 }</td>
            <td>{i.Watt == 20 ? parseInt(i.Mouches) * 190 : parseInt(i.Mouches) * 300  }</td>
            <td>{i.Watt == 20 ? parseInt(i.Papillons) * 190 : parseInt(i.Papillons) * 300}</td>
            <td>{i.Watt == 20 ? parseInt(i.Abeilles) * 190 : parseInt(i.Abeilles) * 300}</td>
            <td>{i.Watt == 20 ? parseInt(i.Moustiques) * 190 : parseInt(i.Moustiques) * 300}</td>
            <td>{i.ACTION}</td>
          </tr>
          ))}
          
        </tbody>
      </table>

      <p><em>*Méthode de calcule: Si la surface totale est de 100 cm² et la moyenne par cm² est de 5 insectes : Nombre Total d'insectes = 5 x 100 = 500 insectes.</em></p>

      <table className="recommendations-table">
        <tbody>
          <tr>
            <td className="section-title" colSpan="2">RECOMMANDATIONS</td>
          </tr>
          <tr>
            <td width="50%">Observations</td>
            <td>CONSEILS</td>
          </tr>
          <tr style={{height:"120px"}}>
            <td>{observation}</td>
            <td>
            {conseils}
            </td>
          </tr>
        </tbody>
      </table>
    </div>



        </Box>
    );
}

export default Passages;
