import React, { useRef, useEffect,useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import Navbar from './NavbarC.js';
import Footer from './FooterC.js';
import DefaultPlan from './img/DefaultPlan.jpeg';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import axios from 'axios';
import Konva from 'konva';
import fulllogo from './img/full_logo_png.png'

const produitsData = [
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023',organisme: 'Rats', authorization: '001' },
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023', organisme: 'Rats', authorization: '001' },
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023', organisme: 'Rats', authorization: '001' },
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023', organisme: 'Rats', authorization: '001' },
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023', organisme: 'Rats', authorization: '001' }
];

const DocumentData = [
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },

];

function Accueil() {
  const [produitsData , setproduitsData] = useState([])
    const [activeTab, setActiveTab] = useState('Plan');
    const [currentPage, setCurrentPage] = useState(1);
    const containerRef = useRef(null);
    const rowsPerPage = 4; // Number of rows per page
    const certificateRef = useRef();
    const [currentPageD, setCurrentPageD] = useState(1);
    const rowsPerPageD = 4; // Number of rows per page

    const [postsData , setpostsData] = useState([])
    const [piegesData , setpiegesData] = useState([])
    const [CodeRef, setCodeRef] = useState("");
    const [Type, setType] = useState("");
    const [Etat, setEtat] = useState("");
    const [selectedShape, setselectedShape] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [initialImageState, setInitialImageState] = useState({
      src: null,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
      rotation: 0
    });
    
    const [rectCount, setRectCount] = useState(4);



    const formatDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };

      useEffect(()=>{
        console.log(JSON.parse(localStorage.getItem('UserData')))
      })

    useEffect(() => {
      if( activeTab === 'Plan'){
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        
        const stage = new Konva.Stage({
          container: containerRef.current,
          width: containerWidth,
          height: containerHeight,
        });
    
        const layer = new Konva.Layer();
        stage.add(layer);
    
        const initialRects = [];
    
        let selectedShape = null;

    
        function createTitleBlock(stage, layer,convention) {
    
          const titleGroup = new Konva.Group({
            x: 10,
            y: 10,
          });
        
          const outerRect = new Konva.Rect({
            width: 300,
            height: 170,
            stroke: 'black',
            strokeWidth: 2
          });
        
          const innerTopRect = new Konva.Rect({
            y: 55,
            width: 300,
            height: 5,
            stroke: 'black',
            strokeWidth: 1
          });
        
          const innerBottomRect = new Konva.Rect({
            y: 60,
            width: 300,
            height: 30,
            stroke: 'black',
            strokeWidth: 1
          });
          const innerBottomRect2 = new Konva.Rect({
            y: 95,
            width: 300,
            height: 35,
            stroke: 'black',
            strokeWidth: 1
          });
          const daterect = new Konva.Rect({
            y: 135,
            width: 100,
            height: 35,
            stroke: 'black',
            strokeWidth: 1
          });
          const datetext = new Konva.Text({
            x: 10,
            y: 150,
            text: "Date: "+formatDate(),
            fontSize: 8,
            fontStyle: 'bold'
          });
          const echrect = new Konva.Rect({
            x:100,
            y: 135,
            width: 100,
            height: 35,
            stroke: 'black',
            strokeWidth: 1
          });
          const echelletext = new Konva.Text({
            x: 110,
            y: 150,
            text: "Echelle: 1/200 ",
            fontSize: 8,
            fontStyle: 'bold'
          });
          const realiserrect = new Konva.Rect({
            x:200,
            y: 135,
            width: 100,
            height: 35,
            stroke: 'black',
            strokeWidth: 1
          });
          const realisertext = new Konva.Text({
            x: 210,
            y: 150,
            text: "Etabli par: Med",
            fontSize: 8,
            fontStyle: 'bold'
          });
          const logo = new Image();
          logo.src = fulllogo;
          const pestima = new Konva.Image({
            x: 100,
            y: 5,
            image:logo,
            width:90,
            height:30
          });
        
          const planText = new Konva.Text({
            x: 90,
            y: 35,
            text: "Plan d'appatage",
            fontSize: 15
          });
        
          const zalarText = new Konva.Text({
            x: 10,
            y: 68,
            text: convention ,
            fontSize: 14
          });
        
          const planMasseText = new Konva.Text({
            x: 10,
            y: 105,
            text: 'PLAN MASSE',
            fontSize: 14,
            fontStyle: 'bold'
          });
        
          titleGroup.add(outerRect, innerTopRect, daterect,datetext,echrect,echelletext,realiserrect,realisertext,innerBottomRect2,innerBottomRect,planText ,pestima,  zalarText, planMasseText);
          layer.add(titleGroup);
          layer.draw();
        }
    
        
    
    
    
        function createTrapezoid(width, height) {
          const topWidth = width * 0.8;
          const sideOffset = (width - topWidth) / 2; // Make the top 70% of the bottom width
          return new Konva.Shape({
            sceneFunc: function (context, shape) {
              context.beginPath();
              context.moveTo(sideOffset, 0);
              context.lineTo(width - sideOffset, 0);
              context.lineTo(width, height);
              context.lineTo(0, height);
              context.closePath();
              context.fillStrokeShape(shape);
            },
            width: width,
            height: height,
            fill: '#000000',
          });
        }
    
        function createRect(rectData) {
          const group = new Konva.Group({
            x: rectData.x,
            y: rectData.y,
            rotation: rectData.rotation,
          });
        
          let box;
          if (rectData.type === "piege") {
            box = createTrapezoid(rectData.width, rectData.height);
          } else {
            box = new Konva.Rect({
              width: rectData.width,
              height: rectData.height,
              fill: rectData.fill,
              cornerRadius: rectData.cornerRadius 
            });
          }
        
          const text = new Konva.Text({
            text: rectData.id.toString(),
            fontSize: (rectData.width + rectData.height) / 7.5,
            fontFamily: 'Arial',
            fill: rectData.fillText,
            width: rectData.width,
            height: rectData.height,
            align: 'center',
            verticalAlign: 'middle',
          });
    
          const deleteButton = new Konva.Group({
            x: rectData.width - 20,
            y: -10,
            visible: false,
          });
    
          const deleteCircle = new Konva.Circle({
            radius: 10,
            fill: 'red',
          });
    
          const deleteX = new Konva.Text({
            text: 'X',
            fontSize: 15,
            fontFamily: 'Arial',
            fill: 'white',
            align: 'center',
            verticalAlign: 'middle',
            x: -5,
            y: -7,
          });
    
          deleteButton.add(deleteCircle);
          deleteButton.add(deleteX);
    
          group.add(box);
          group.add(text);
          group.add(deleteButton);
    
          group.id('rect-' + rectData.idShape);
    
          layer.add(group);
          
          return {etat : true , group : group} ;
        }
        
    
        
        function addTransformer(group) {
          const transformer = new Konva.Transformer({
            nodes: [group],
            keepRatio: false,
            boundBoxFunc: function (oldBox, newBox) {
              newBox.width = Math.max(30, newBox.width);
              newBox.height = Math.max(30, newBox.height);
              return newBox;
            },
          });
          layer.add(transformer);
        }
    
        function removeTransformer(group) {
          const transformer = group.getParent().find('Transformer')[0];
          if (transformer) {
            transformer.destroy();
            UpdateInDataBase(group);
            setselectedShape(false);
          }
        }
    
        initialRects.forEach(createRect);
        layer.draw();
    
        function addRectangle(data) {
          setRectCount((prevCount) => {
            let newRect
            if( data.fromDataBase){
              console.log("fromdatabase")
              newRect = {
                id: data.id,
                idShape:data.idShape,
                x: parseFloat(data.x),
                y:  parseFloat(data.y),
                width:  parseFloat(data.width), // Make piège slightly narrower
                height:  parseFloat(data.height), // Make piège slightly taller
                rotation: parseFloat(data.rotation),
                fill: data.type === "post" ? "#AFE1AF" : "#000000",
                type: data.type,
                etat: data.etat,
                codeRef: data.codeRef,
                fillText: data.type === "post" ? "#000000" : "#fff",
                cornerRadius: data.type === "post" ? 0 : 0, // Remove corner radius for piège
              };
            }else{
              console.log("not from database")
              newRect = {
                id: data.id,
                x:  Math.random() * (stage.width() - 100),
                y:  Math.random() * (stage.height() - 50),
                width:  data.type === "piege" ? 80 : 100, // Make piège slightly narrower
                height:  data.type === "piege" ? 60 : 50, // Make piège slightly taller
                rotation: 0,
                fill: data.type === "post" ? "#AFE1AF" : "#000000",
                type: data.type,
                etat: data.etat,
                codeRef: data.codeRef,
                fillText: data.type === "post" ? "#000000" : "#fff",
                cornerRadius: data.type === "post" ? 0 : 0, // Remove corner radius for piège
              };
            }
    
            const StateCreate = createRect(newRect);
            layer.draw();
            if(!data.fromDataBase && StateCreate.etat){
              SendShapeData(newRect , StateCreate.group );
            }
            
            return prevCount + 1;
          });
        }
        function SendShapeData(data , group){
                axios.post("https://serv.pestima.yuliagroupe.com/api/shapes",
                  {id_plan:1,ID_Piege: data.type === 'piege' ? data.id : 0 , 
                    ID_Post :data.type === 'post' ? data.id : 0  , x :data.x.toFixed(2) ,
                    y:data.y.toFixed(2) , height:data.height , width:data.width , rotation:data.rotation.toFixed(2)
                  })
                .then((res)=>{
                  group.id('rect-' + res.data.id_shape)
                  console.log("bien envoiyer")
                })
        }
    
        function UpdateInDataBase(group) {
          console.log('Current shape position:');
          const id = group.id().split('-')[1];
          const position = group.position();
          const rotation = group.rotation();
          
          // Find the shape (either Rect or Shape for trapezoid)
          const shape = group.findOne('Rect') || group.findOne('Shape');
          
          if (shape) {
              const width = shape.width();
              const height = shape.height();
              const type = shape instanceof Konva.Rect ? 'Post' : 'Piège';
              
              axios.put("https://serv.pestima.yuliagroupe.com/api/shapes/"+id,
                {
                  x :position.x.toFixed(2) ,
                  y:position.y.toFixed(2) , height:height.toFixed(2) , width:width.toFixed(2) , rotation: rotation.toFixed(2)
                })
              .then((res)=>{
                console.log("bien changer")
                console.log(res.data)
              })
          }
      }
    
      function DeleteShape(group){
        const id = group.id().split('-')[1];
        axios.delete("https://serv.pestima.yuliagroupe.com/api/shapes/"+id)
        .then((res)=>{
          console.log("bien supp")
        })
      }
    
    
        function logRectPositions() {
          console.log('Current shape positions:');
          stage.find('Group').forEach(function (group) {
            if (group.getParent() === layer) {
              const id = group.id().split('-')[1];
              const position = group.position();
              const rotation = group.rotation();
              
              // Find the shape (either Rect or Shape for trapezoid)
              const shape = group.findOne('Rect') || group.findOne('Shape');
              
              if (shape) {
                const width = shape.width();
                const height = shape.height();
                const type = shape instanceof Konva.Rect ? 'Post' : 'Piège';
                
                console.log(
                  `${type} ${id}: x = ${position.x.toFixed(2)}, y = ${position.y.toFixed(2)}, ` +
                  `width = ${width.toFixed(2)}, height = ${height.toFixed(2)}, rotation = ${rotation.toFixed(2)}`
                );
              }
            }
          });
        }
        window.addRectangle = addRectangle;
    
        logRectPositions();
        const imageObj = new Image();
        imageObj.src = 'https://serv.pestima.yuliagroupe.com/api/plans/'+1;
    
        const img = new Konva.Image({
          x: 0,
          y: 0,
          width: containerWidth,
          height: containerHeight,
          image:imageObj
        });
        layer.add(img);
        setBackgroundImage(img);
    
        axios.get("https://serv.pestima.yuliagroupe.com/api/plan/"+1)
        .then((res)=>{
          createTitleBlock(stage, layer,res.data.convention.client.entreprises[0] ? res.data.convention.client.entreprises[0].Nom :res.data.convention.client.personnes[0].Nom);
        })
    
        
        stage.on('wheel', (e) => {
          e.evt.preventDefault();
          const scaleBy = 1.1;
          const oldScale = stage.scaleX();
        
          const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
          };
        
          let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        
          if (newScale < 1) {
            newScale = 1;
          }
        
          const newPos = {
            x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
            y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
          };
        
          if (e.evt.deltaY < 0 && newScale === 1) {
            newPos.x = 0;
            newPos.y = 0;
          }
        
          stage.scale({ x: newScale, y: newScale });
          stage.position(newPos);
          stage.batchDraw();
        });
    
      axios.get("https://serv.pestima.yuliagroupe.com/api/posts")
      .then((res)=>{
        setpostsData(res.data);
      })
    
      axios.get("https://serv.pestima.yuliagroupe.com/api/pieges")
      .then((res)=>{
        setpiegesData(res.data);
      })
    
      axios.get("https://serv.pestima.yuliagroupe.com/api/shapesbyplan/"+1)
      .then((res) => {
        if (res.data.length > 0) {
          const postDataArray = res.data.filter(post => post.ID_Piege === "0");
          const piegeDataArray = res.data.filter(post => post.ID_Post === "0");
          postDataArray.forEach(postData => {
            const data = {
              id: postData.ID_Post,
              idShape : postData.id_shape,
              type: "post",
              etat: postData.post.etat.Etat,
              codeRef: postData.post.Code_Ref,
              x: postData.x,
              y: postData.y,
              height: postData.height,
              width: postData.width,
              rotation: postData.rotation,
              fromDataBase: true
            };
            console.log(postData);
            addRectangle(data);
          });
    
          piegeDataArray.forEach(postData => {
            const data = {
              id: postData.ID_Piege,
              idShape : postData.id_shape,
              type: "piege",
              etat: postData.post.etat.Etat,
              codeRef: postData.piege.Code_Ref,
              x: postData.x,
              y: postData.y,
              height: postData.height,
              width: postData.width,
              rotation: postData.rotation,
              fromDataBase: true
            };
            console.log(postData);
            addRectangle(data);
          });
    
        }
      });
    }else if(activeTab === "Produits"){
      axios.get("https://serv.pestima.yuliagroupe.com/api/stocks/client/"+JSON.parse(localStorage.getItem('UserData')).id_client)
      .then((res) => {
        const structuredData = res.data.map(produit => ({
          Nom: produit.produit.nom_produits,
          matieres_actives: produit.produit.matieres_actives,
          dateDePermption: produit.Date_Expiration,
          ORGANISME_CIBLE: produit.produit.ORGANISME_CIBLE,
          NAutorisation: produit.produit.NAutorisation
        }));
    
        // Fetch the second data set
        return axios.get('https://serv.pestima.yuliagroupe.com/api/deraptilisationpassages/filterByClientAndUsage/'+JSON.parse(localStorage.getItem('UserData')).id_client)
          .then((res2) => {
            const additionalData = res2.data.map(produit => ({
              Nom: produit.stock.produit.nom_produits,
              matieres_actives: produit.stock.produit.matieres_actives,
              dateDePermption: produit.stock.Date_Expiration,
              ORGANISME_CIBLE: produit.stock.produit.ORGANISME_CIBLE,
              NAutorisation: produit.stock.produit.NAutorisation
            }));
            const combinedData = [...structuredData, ...additionalData];
            setproduitsData(combinedData);
          });
      })

    
    }
    
    
      }, [activeTab]);





    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handleNextPageD = () => {
        setCurrentPageD((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    const handlePreviousPageD = () => {
        setCurrentPageD((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    // Calculate the data to show on the current page
    const paginatedData = produitsData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const paginatedDataDocument = DocumentData.slice(
        (currentPageD - 1) * rowsPerPageD,
        currentPageD * rowsPerPageD
    );



    const renderContent = () => {
        switch (activeTab) {
            case 'Plan':
                return (
                    <Box sx={{ mt: 2 }}>
                        <Box sx={{width:"100%" , height:"100%" , display:"flex" ,justifyContent:"center"}}>
                        <Box
                        id="container"
                        ref={containerRef}
                        component="div"
                        sx={{ 
                            flexGrow: 1, 
                            p: 0, 
                            width: '80%', 
                            height: '452px', 
                            backgroundColor: '#8B4513', 
                            overflowX: 'hidden',
                            overflowY: 'hidden'
                        }}
                        >
                        </Box>
                        </Box>

                       <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 3 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ bgcolor: 'green', width: '20px', height: '20px', mr: 1 }} />
                                <Typography sx={{ color: 'black', fontWeight: 'bold' }}>Post Bon Etat</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ bgcolor: 'yellow', width: '20px', height: '20px', mr: 1 }} />
                                <Typography sx={{ color: 'black', fontWeight: 'bold' }}>Post inaccessible</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ bgcolor: 'black', width: '20px', height: '20px', mr: 1 }} />
                                <Typography sx={{ color: 'black', fontWeight: 'bold' }}>Piège</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                </Box>
                    </Box>
                );
            case 'Produits':
                return (
                    <Box sx={{ mt: 2 }}>
                        <TableContainer style={{ borderRadius: '9px', border: '1px solid #C0C0C0', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Nom</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Matières Actives</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Date de péremption</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Organisme Cible</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">N D’autorisation</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Certificate</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData.map((produit, index) => (
                                        <TableRow 
                                            key={index} 
                                            sx={{ borderBottom: '1px solid #C0C0C0' }} // Add border to each row
                                        >
                                            <TableCell sx={{border:"0px"}}>{produit.Nom}</TableCell>
                                            <TableCell sx={{border:"0px"}}>{produit.matieres_actives ? produit.matieres_actives.map(ma => ma.Matieres_actives).join(', ') : null}</TableCell>
                                            <TableCell sx={{border:"0px"}}>{produit.dateDePermption}</TableCell>
                                            <TableCell sx={{border:"0px"}}>{produit.ORGANISME_CIBLE}</TableCell>
                                            <TableCell sx={{border:"0px"}}>{produit.NAutorisation}</TableCell>
                                            <TableCell sx={{border:"0px"}}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        bgcolor: '#930e0e',
                                                        borderRadius: '9px',
                                                        textTransform: 'none',
                                                        color: 'white',
                                                        '&:hover': { bgcolor: '#a9281e' },
                                                    }}
                                                >
                                                    Telecharger
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Pagination controls */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                            <IconButton 
                                onClick={handlePreviousPage} 
                                disabled={currentPage === 1} 
                                sx={{ color: '#930e0e' }} // Red color for arrow
                            >
                                <PlayArrowIcon sx={{ transform: 'rotate(180deg)' }} />
                            </IconButton>
                            <Typography sx={{ mx: 2 }}>{currentPage}</Typography>
                            <IconButton 
                                onClick={handleNextPage} 
                                disabled={currentPage * rowsPerPage >= produitsData.length} 
                                sx={{ color: '#930e0e' }} // Red color for arrow
                            >
                                <PlayArrowIcon />
                            </IconButton>
                        </Box>
                    </Box>
                );
            case 'Documents':
                return (
                    <Box sx={{ mt: 2 }}>
                    <TableContainer style={{ borderRadius: '9px', border: '1px solid #C0C0C0', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Nom de Documents</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Telechargement</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedDataDocument.map((produit, index) => (
                                    <TableRow 
                                        key={index} 
                                        sx={{ borderBottom: '1px solid #C0C0C0' }} // Add border to each row
                                    >
                                        <TableCell sx={{border:"0px"}}>{produit.name}</TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination controls */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                        <IconButton 
                            onClick={handlePreviousPageD} 
                            disabled={currentPageD === 1} 
                            sx={{ color: '#930e0e' }} // Red color for arrow
                        >
                            <PlayArrowIcon sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                        <Typography sx={{ mx: 2 }}>{currentPageD}</Typography>
                        <IconButton 
                            onClick={handleNextPageD} 
                            disabled={currentPageD * rowsPerPageD >= DocumentData.length} 
                            sx={{ color: '#930e0e' }} // Red color for arrow
                        >
                            <PlayArrowIcon />
                        </IconButton>
                    </Box>
                </Box>
                )
            default:
                return null;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar activeButton={"Accueil"}/>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Button
                    onClick={() => handleTabChange('Plan')}
                    sx={{
                        bgcolor: activeTab === 'Plan' ? '#930e0e' : 'white',
                        color: activeTab === 'Plan' ? 'white' : 'black',
                        borderRadius: '9px',
                        textTransform: 'none',
                        width: '150px',
                        mx: 1,
                        '&:hover': { bgcolor: activeTab === 'Plan' ? '#a9281e' : '#f5f5f5' },
                    }}
                >
                    Plan
                </Button>
                <Button
                    onClick={() => handleTabChange('Produits')}
                    sx={{
                        bgcolor: activeTab === 'Produits' ? '#930e0e' : 'white',
                        color: activeTab === 'Produits' ? 'white' : 'black',
                        borderRadius: '9px',
                        textTransform: 'none',
                        width: '150px',
                        mx: 1,
                        '&:hover': { bgcolor: activeTab === 'Produits' ? '#a9281e' : '#f5f5f5' },
                    }}
                >
                    Produits
                </Button>
                <Button
                    onClick={() => handleTabChange('Documents')}
                    sx={{
                        bgcolor: activeTab === 'Documents' ? '#930e0e' : 'white',
                        color: activeTab === 'Documents' ? 'white' : 'black',
                        borderRadius: '9px',
                        textTransform: 'none',
                        width: '150px',
                        mx: 1,
                        '&:hover': { bgcolor: activeTab === 'Documents' ? '#a9281e' : '#f5f5f5' },
                    }}
                >
                    Documents
                </Button>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {renderContent()}
                
            </Box>
            <Footer />
        </Box>
    );
}

export default Accueil;
